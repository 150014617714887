
/* --------------------------- 약관 및 정책 --------------------------- */
.cptTermsService{
    width:100%; border:1px solid #dcdcdc; background:#fcfcfc; padding:80px 0; border-radius:25px;
    &.noLine {
        border:none; background:none; padding:0; border-radius:0;
        p {text-align:left;}
    }

    .baseTitle{
        font-size:24px; font-weight:700; line-height:1.5; color:#000; text-align:center;
    }

    .baseMsg{
        margin-top:10px; font-size:16px; line-height:1.5; font-weight:400; color:#000; text-align:center;

        &:first-child{
            margin-top:0;
        }
    }

    .listBtnArea{
        margin-top:60px; display:flex; justify-content: space-between;

        .item{
            width:310px; height:370px; padding:0 24px; border-radius:25px; transition:background 0.2s; position:relative;

            &.type01{background:#fff url(../../images/sub/bg_loan_300.png) center 113px / 203px no-repeat; border:1px solid #dcdcdc;}
            &.type02{background:#fff url(../../images/sub/bg_loan_house.png) center 114px / 211px no-repeat; border:1px solid #dcdcdc;}
            &.type03{background:#fff url(../../images/sub/bg_loan_car.png) center 143px/177px no-repeat; border:1px solid #dcdcdc;}
            &:hover{
                background-color:#f1f9ff; color:#fff; 
                .btnName{
                    color:#000; 
                }
                .serviceBtn{
                    color:#fff; background:#64bdff;
                }
            }
            .btnName{
                width:100%; padding-top:50px; font-size:32px; font-family:"YangPyeongM"; font-weight:500; color:#000; line-height: 1; text-align:center;
            }
            .serviceBtn{
                width:calc(100% - 48px); height:56px; font-size:16px; font-weight:400; color:#000; line-height: 54px; text-align: center; 
                background:#f3f3f3; border-radius:28px; display:block; position:absolute; left:24px; bottom:30px; transition:background 0.2s;
            }
        }
    }

    .btnArea{
        margin-top:40px; width:100%; display:flex; justify-content: center;

        .serviceBtn{
            height:60px; padding:0 35px; font-size:18px; font-weight:500; color:#fff; text-align:center; line-height:60px; border-radius:5px; background:var(--key-blue01); display:block;
        }
    }
}

/* --------------------------- 약관 및 정책 --------------------------- */
.cptTermsPrivacy{
    .effectiveDate{
        width:100%;

        & + .modTab {margin-top: 60px;}

        .text{
            font-size:20px; font-weight:bold; color:#000; line-height:42px; display:block; margin-bottom:20px;
        }
        .date{
            width:420px; display:inline-block; margin-right:7px; vertical-align:middle;
            select{
                width:100%; height:65px; padding:20px; font-size:16px; font-weight:400; color:#444; border:2px solid #dcdcdc; border-radius:10px;
                background:url(../../images/sub/icon_dropdown.png) calc(100% - 20px) center no-repeat;
            }
        }
        .schBtn{
            display:inline-block; vertical-align:middle; height:65px; line-height:65px; padding: 0 50px; font-size:16px; font-weight:500; color:#fff; border-radius:10px; background:var(--key-blue01);
        }
    }

    .privacyPlace{
        width:100%; margin-top:40px;

        &:first-child{
            margin-top:0;
        }
        .baseMsg {
            margin-top:20px; font-size:16px; line-height:1.5; font-weight:400; color:#000;

            &:first-child{
                margin-top:0;
            }
        }
        .baseList {
            margin-top:20px; padding-left:14px; font-size:16px; line-height:1.5; font-weight:400; color:#000; position:relative;

            &::before{
                content:""; width:4px; height:4px; background:#000; border-radius:50%; display:block; position:absolute; left:0; top:11px;
            }
            &:first-child{
                margin-top:0;
            }
        }
        .baseTitle {
            margin-top:40px; font-size:18px; line-height:1.5; font-weight:700; color:#000;

            &:first-child{
                margin-top:0;
            }
        }
        .topTitle {
            margin-top:40px; font-size:24px; line-height:1.5; font-weight:700; color:#000;

            &:first-child{
                margin-top:0;
            }
        }
    }
}

/* --------------------------- 고객센터 > 금융소비자 안내 --------------------------- */
.cptContFinance{
    width:100%; 

    .contBox{
        width:100%; margin-top:60px; padding:50px; border:1px solid #dcdcdc; border-radius:15px; background:#fcfcfc;

        .boxTit{
            margin-top:20px; font-size:24px; line-height:1.5; font-weight:bold; color:#000; text-align: center;

            .keyColor{
                font-weight:700; color:var(--key-blue01);
            }
            &:first-child{margin-top:0px;}
        }
        .boxB{
            margin-top:20px; font-size:18px; line-height:1.5; font-weight:500; color:#000;
            &:first-child{margin-top:0px;}
        }
        .boxText{
            margin-top:20px; font-size:16px; line-height:1.5; font-weight:400; color:#000;
            &.center {text-align: center;}
            &:first-child{margin-top:0px;}
        }
        .comBtnGroup {margin-top:24px;}

        .boxBtn{
            text-align:center; margin-top:24px;
            button{
                padding:11px 20px; border-radius:10px; background:#005ce6; font-size:16px; color:#fff; font-weight:400; text-align:center; line-height:1.5;
            }
        }
        &.type01{
            padding:45px; border:0; border-radius:25px; border:1px solid #d1e7ff; background:#f2f9ff url(../../images/sub/finance_img01.png) right 33px bottom 0 no-repeat;

            .boxTit {
                text-align:left;
            }
        }
        &.type02{
            padding:45px; border:1px solid #dcdcdc; border-radius:15px; background:#fcfcfc;
        }
        &:first-child{margin-top:0;}
    }

    .financeTitle{
        width:100%; margin-top:40px; font-size:18px; line-height:1.5; font-weight:700; color:#000;
    }
    .comContTit + .financeTitle{
        margin:0 0 20px;
    }

    .exTitle{
        margin:20px 0; color:#000;

        &:first-child{margin-top:0;}
        &.xl{   //h5
            margin:60px 0 20px; font-size:20px; font-weight:700;  display:block;
            &:first-child{margin:0 0 20px;}
        }
        &.lg{   //h6
            margin:60px 0 20px; font-size:18px; font-weight:700;  display:block;
            &:first-child{margin:0 0 20px;}
        }
        &.nm{   //b
            font-size:16px; font-weight:500; display:block;
        }
        &.sm{   //div
            font-size:18px; font-weight:500;  display:block;
        }
    }
    .cptTable + .exTitle{margin-top:40px;}

    .baseList{
        width:100%; margin-top:20px;

        .item{
            margin-top:8px; padding-left:9px; font-size:16px; font-weight:400; line-height:1.5; color:#000; position:relative;
            &::before{content:"-"; display:block; position:absolute; left:0; top:-1px}
            &:first-child{margin-top:0;}
        }
    }
    .cptTable + .baseList{margin-top:40px;}
    .baseList + .cptTable{margin-top:20px;}
    .titAdd{
        font-size:20px; line-height:42px; font-weight:bold; color:#000;
        &.withSpace {margin:40px 0 20px;}
    }
    .baseText{
        margin-top:20px; font-size:16px; font-weight:400; line-height:1.5; color:#000;

        &.xl{font-size:24px;}
        &:first-child{margin-top:0;}
    }

    .cardGroup{
        width:100%; margin-top:20px;  display:flex; flex-wrap:wrap; gap:16px;
        .item {
            width:336px; height:226px; padding:40px; border:1px solid #dcdcdc; border-radius:15px; background:#fcfcfc;

            .cardCont {
                .cardTit{
                    font-size:20px; line-height:24px; font-weight:700; color:#000; position:relative; width:max-content;

                    &::after {
                        content: ''; position:absolute; top:50%; right:-40px; width:32px; height:32px; transform:translateY(-50%);
                    }

                    &.card01::after {background:url(../../images/sub/ico_card01.png)50% 50% no-repeat;}
                    &.card02::after {background:url(../../images/sub/ico_card02.png)50% 50% no-repeat;}
                    &.card03::after {background:url(../../images/sub/ico_card03.png)50% 50% no-repeat;}
                    &.card04::after {background:url(../../images/sub/ico_card04.png)50% 50% no-repeat;}
                    &.card05::after {background:url(../../images/sub/ico_card04.png)50% 50% no-repeat;}
                    &.card06::after {background:url(../../images/sub/ico_card04.png)50% 50% no-repeat;}
                }
                .cardText{
                    font-size:16px; font-weight:400; line-height:1.5; color:#000; margin-top:20px;
                    > .ex {font-size:14px; color:#999;}
                }
            }
        }
    }

    .infoTxt {
        p {
            font-size:16px; color:#000; font-weight:400; line-height:24px; letter-spacing:-0.4px; margin-top:20px;
            span {
                display:inline-block; margin-left:15px;
            }
        }

        p + p {
            margin-top:10px
        }

    }

    .comBtnGroup {
        margin-top:40px;
    }

    .boxCover {
        width:100%; padding:80px 0; border:1px solid #dcdcdc; background:#fcfcfc; border-radius:25px;
        .baseText {font-size:24px; color:#000; font-weight:bold; text-align: center; line-height:32px; letter-spacing: -0.6px;}
        .infoTxt {
            margin-top:20px;
            > p {
                font-size:18px; color:#000; font-weight:400; line-height:1; text-align:center; letter-spacing: -0.45px;
                > span {display:block; margin-top:40px; font-size:16px; color:#999; font-weight:400; line-height:26px; letter-spacing:-0.4px;}
            }
        }
    }
}