
/* -------------------------------- 보드 상단 -------------------------------- */
.comBoardTop{
    width:100%; margin-top: 40px; margin-bottom:20px; display:flex; flex-wrap:nowrap; justify-content:space-between;

    &:first-of-type {margin-top: 0;}

    .leftArea{
        display:flex; align-items:end;

        .total{
            font-size:18px; font-weight:500; line-height:1.5; color:#000;

            .c_blue{
                color:var(--key-blue01);
            }
        }
    }

    .rightArea{
        margin-left: auto;
        .searchGroup{
            position:relative; display: inline-flex; column-gap: 8px;

            select {width: 120px; height:50px; padding:0 50px 0 20px; font-size:14px; color:#444; border:0; border:1px solid #dcdcdc; border-radius:25px; background:#fff url(../../images/common/slct_arr.svg) calc(100% - 20px) center no-repeat;}

            .searchIpt{
                width:320px; height:50px; padding:0 50px 0 20px; font-size:14px; color:#444; border:0; border:1px solid #dcdcdc; border-radius:25px; background:#fff;
            }
            .searchBtn{
                width:40px; height:40px; font-size:0; border-radius:50%; background:url(../../images/common/ico_search.svg) center / 16px no-repeat; position:absolute; right:5px; top:5px;
            }
        }
    }
}

/* -------------------------------- 페이징 -------------------------------- */
.comPaging{
    width:100%; margin-top:40px; text-align:center;

    .first{
        width:30px; height:33px; font-size:0; vertical-align:top; display:inline-block; position:relative;

        &::before{
            content: ""; width:7px; height:7px; border-top:1px solid #666; border-right:1px solid #666; transform:rotate(-135deg); display:block; position:absolute; left:11px; top:12px;
        }
        &::after{
            content: ""; width:7px; height:7px; border-top:1px solid #666; border-right:1px solid #666; transform:rotate(-135deg); display:block; position:absolute; left:16px; top:12px;
        }
    }
    .prev{
        width:30px; height:33px; font-size:0; vertical-align:top; margin-left:2px; display:inline-block; position:relative;
        
        &::before{
            content: ""; width:7px; height:7px; border-top:1px solid #666; border-right:1px solid #666; transform:rotate(-135deg); display:block; position:absolute; left:13px; top:12px;
        }
    }
    .next{
        width:30px; height:33px; font-size:0; vertical-align:top; margin-right:5px; display:inline-block; position:relative;

        &::before{
            content: ""; width:7px; height:7px; border-top:1px solid #666; border-right:1px solid #666; transform:rotate(45deg); display:block; position:absolute; left:9px; top:12px;
        }
    }
    .end{
        width:30px; height:33px; font-size:0; vertical-align:top; display:inline-block; position:relative;

        &::before{
            content: ""; width:7px; height:7px; border-top:1px solid #666; border-right:1px solid #666; transform:rotate(45deg); display:block; position:absolute; left:6px; top:12px;
        }
        &::after{
            content: ""; width:7px; height:7px; border-top:1px solid #666; border-right:1px solid #666; transform:rotate(45deg); display:block; position:absolute; left:11px; top:12px;
        }
    }
    .pageGroup{
        margin:0 18px; vertical-align:top; display:inline-block;

        .item{
            width:34px; height:33px; text-align:center; vertical-align:top; display:inline-block;

            a{
                padding:5px 3px; font-size:14px; font-weight:500; line-height:1.5; color:#000; display:inline-block;
            }
            &.on{
                a{
                    color:var(--key-blue01); border-bottom:2px solid var(--key-blue01);
                }
            }
        }
    }
}

/* -------------------------------- 게시판 상세 첨부파일 -------------------------------- */
.comBoardFile {
    width:100%; border-bottom:1px solid #dcdcdc;
    .fileArea{
        .fileGroup{
            padding:25px 20px;
            .fileItem{
                margin-top:10px;

                &:first-child{margin-top:0;}
                
                .download{
                    width:max-content; font-size:16px; font-weight:400; line-height:1.5; color:#444; padding:5px 31px 5px 27px; display:block; position:relative;
                    &::before{
                        content:""; width:20px; height:20px; background:url(../../images/common/ico_file.svg) center no-repeat; 
                        display:block; position:absolute; left:0; top:7px;
                    }
                    &::after{
                        content:""; width:24px; height:24px; background:url(../../images/common/ico_noti_download.svg) center no-repeat; 
                        display:block; position:absolute; right:0; top:5px;
                    }
                }
            }
        }   
    }
}

/* -------------------------------- 게시물 이전 & 다음글 -------------------------------- */
.comBoardPrvNxt {
    width:100%; 

    .board {
        width:100%; display:flex; flex-flow:row nowrap; align-items:left; justify-content: space-between; padding:30px 20px; border-bottom:1px solid #dcdcdc;

        .fCover {
            display:flex; flex-flow:row nowrap; align-items:left; justify-content: space-between;

            .type {
                font-size:16px; color:#444; font-weight:500; line-height:1; letter-spacing:-0.4px;
            }

            .title {
                margin-left:40px; font-size:16px; color:#444; font-weight:400; line-height:1; letter-spacing: -0.4px;

                &.noData {
                    font-size:16px; color:#999; font-weight:400; line-height:1;
                }
            }

            .date {
                display:inline-block; width:88px; font-family: "RedHatDisplay"; font-size:16px; color:#888; font-weight:500; line-height:1;
            }
        }
    }
}

/*--------------------------------- 테이블 --------------------------------*/
.comAccorTable {
    width: 100%;
    .accorThead {
        border-top: 1px solid #dcdcdc;border-bottom: 1px solid #dcdcdc; display: flex; justify-content: flex-start; align-items: center;
        .td {
            height: 60px; border-right: 1px solid #dcdcdc; text-align: center; line-height: 59px; font-size: 16px; color: #000; background: #f5f5f5; flex: 0 0 33.3333%;
            &:last-child {border-right:none;}
        }
    }
    .accorTbody {
        width: 100%;
        .tr {
            .accorTit {
                width: 100%; display: flex; position: relative;border-bottom: 1px solid #dcdcdc;
                &::after {display: block; content: ""; width: 18px;height: 10px; background: url(../../images/common/acc_arr.svg) center no-repeat; position: absolute; right: 20px; top: 25px; transition: all 0.3s;}
                .td {padding: 17px 0; text-align: center; font-size: 16px; color: #000; flex: 0 0 33.3333%; }

                &.nowOn {
                    &::after {transform: rotate(180deg);}
                }
            }

            .accorDt {
                height: auto; max-height: 0; overflow: hidden; transition: all 0.3s;
                .detail {
                    background: #f2f9ff;
                    .dttr {
                        border-bottom: 1px solid #bfdfff; display: flex;
                        .td {padding: 14px 0; text-align: center; font-size: 14px; color: #000; flex: 0 0 33.3333%;}
                    }
                }
            }

            .accorTit.nowOn + .accorDt {
                max-height: 400px;
            }
        }
    }
}


.cptTable {
    width:100%; position:relative;

    table {
        width:100%; table-layout: fixed; border-top:1px solid #000; border-collapse:collapse; border-spacing:0;
        
        tr{
            th {background:#f5f5f5; padding:18px 10px; text-align:center; border-bottom:1px solid #dcdcdc; border-right:1px solid #dcdcdc;}
            td {
                background:#fff; padding:18px 20px; text-align:center; font-size:16px; color:#000; font-weight:400; line-height:1.5; letter-spacing:-0.4px; border-bottom:1px solid #dcdcdc; border-right:1px solid #dcdcdc;
                &:last-of-type {border-right:0;}
                &.bdR { border-right:1px solid #dcdcdc;}

                .downFile {width: 30px; height: 30px; font-size: 0; background: url(../../images/common/ico_pdf.png) center no-repeat; display: inline-block; vertical-align: middle;}
                .subDesc {padding: 10px;font-size: 14px; color: #444; line-height: 1.85; word-break: keep-all;}
            }

            .left {text-align: left;}
        }
    }
    & + & {margin-top: 60px;}

    .tableTop {
        margin-bottom: 20px;
        .frmCombo {
            max-width: 350px;
            button {height:50px; line-height: 49px;}
            .drpdwBox {top:50px;}        
        }
    }

    .comBtnGroup {
        &:last-child {margin-top: 60px;}
    }

    
}

/* -------------------------------- 공통 버튼 -------------------------------- */
.comBtn {
    width:100%; display:flex; flex-flow:row nowrap; align-items:center; margin-top:40px;

    /*정렬*/
    &.center {
        justify-content: center;
    }
    &.left {
        justify-content: flex-start;
    }
    &.right {
        justify-content: flex-end;
    }

    /*버튼타입*/
    .showList {
        width:200px; height:60px; line-height:60px; text-align:center; font-size:16px; color:#000; font-weight:500; border:1px solid #999; border-radius:30px;
    }
}   

/* -------------------------------- 서브컨텐츠 타이틀 -------------------------------- */
.comSubTit {
    margin-bottom: 30px;
    .subTit {
        margin-bottom: 10px; font-size: 24px; color: #000; font-weight: 700;
    }
    .catchphrase {margin-bottom: 10px; font-size: 30px; font-weight: 300; color: #000;}
    .subDesc {
        font-size: 16px; line-height: 32px; color: #000; word-break: keep-all;
        > span.hglt {color:#005ce6;}
    }
    .bgDesc {
        width: 100%; height: 345px; padding: 70px 50px; background: url(../../images/sub/subtit_bg01.png) no-repeat;
        p {
            font-size: 18px; line-height:30px; color: #000;
            > b {color:#469ff9;}
        }

        &.type02 {height: auto; background-image: url(../../images/sub/subtit_bg02.png);}
    }
}

/* -------------------------------- 개별 컨텐츠 소타이틀 -------------------------------- */
.comContTit {
    width: 100%; margin-top: 60px; margin-bottom: 20px; display: flex; flex-wrap: wrap; justify-content: flex-start; align-items: flex-end;
    &:first-child {margin-top: 0;}
    .contTit {font-size: 24px; font-weight: 700; color: #000;}
    .subDesc {width: 100%; margin-top: 5px; font-size: 18px; color: #444; display: inline-block;}
    .morePop {margin-top: 10px; color: #005ce6; font-size: 16px; font-weight: 500; text-decoration: underline; text-underline-position: bottom;}
    .subList {
        margin-top: 20px;
        .listItem {
            margin-bottom: 20px; padding-left: 15px; font-size: 18px; color: #444;position: relative;
            &::after {display: block; content: ""; width: 5px; height: 5px; border-radius: 50%; background: #666; position: absolute; top:10px; left: 0;}
            &:last-child {margin-bottom: 0;}
        }
    }
    .rightSide {
        margin-left: auto;
        i {
            margin-right: 5px; font-style: normal; font-size: 14px; color: #000; display: inline-block; vertical-align: middle;
            &:last-child {margin-right: 0;}
        }
        img {margin: 0 5px; display: inline-block; vertical-align: middle;}
    }
}


/* -------------------------------- 약관 -------------------------------- */
.comClause {
    width: 100%;
    .basicTxt {font-size: 14px; color: #444; line-height: 1.7; word-break: keep-all;}
}

/* -------------------------------- 이미지 설명문(도표) -------------------------------- */
.comImgDesc {
    width: 100%; text-align: center;
    img {max-width: 100%;}
}

/* -------------------------------- 기본 버튼그룹 -------------------------------- */
.comBtnGroup {
    width: 100%;
    .btnArea {
        display: flex; justify-content: center; align-items: center; column-gap: 10px;
        &.left {justify-content: flex-start;}
        &.right {justify-content: flex-end;}
    }

    &.typeInfo {
        width: 100%; margin-top: 60px; border-top: 1px solid #dcdcdc;
        .bfInfo {
            padding: 40px 0 30px; font-size: 18px; color: #444; text-align: center;
            .blue {font-weight: 500; color: #005ce6;}
        }
    }
}

/* -------------------------------- 공통 -------------------------------- */
.hidden {width:1px;height:1px;overflow:hidden;border:0;clip:rect(1px,1px,1px,1px);clip-path:inset(50%);display:inline-block;position:absolute;}

/* txt */
.tBlue {color: var(--key-blue01);}

/* 버튼 */
.btn {
    padding: 0 20px; text-align: center; display: inline-block; transition: all 0.3s;

    // 용도
    &.flow {min-width: 300px; height: 85px; padding: 0 60px; border-radius: 15px; line-height: 83px; font-size: 24px; font-weight: 500;}
    &.crud {min-width: 150px; height: 45px; padding: 0 60px; border-radius: 10px; line-height: 43px; font-size: 16px;}
    &.option {min-width: 65px; height: 45px; border-radius: 10px; font-size: 14px; font-weight: 500;}
    &.map {min-width: 160px; height: 50px; padding: 0 20px; border-radius: 25px; line-height: 48px; text-align: left; font-size: 16px; font-weight: 500; color: #fff; background: #00c300 url("../../images/common/icon_map_w.svg") calc(100% - 20px) center no-repeat;}
    &.print {min-width: 160px; height: 50px; padding: 0 20px; border-radius: 25px; border: 1px solid #707070; line-height: 48px; text-align: left; font-size: 16px; font-weight: 500; color: #000; background: #fff url("../../images/common/icon_print_k.svg") calc(100% - 20px) center no-repeat;}
    &.acting {min-width: 295px; height: 65px; border-radius: 10px; font-size: 16px; font-weight: 500;}
    &.download {
        &::after {display: inline-block; content: ""; width: 28px; height: 28px; margin-left: 5px; background: url(../../images/common/ico_download_w.svg) center no-repeat; vertical-align: middle;}
    }
    &.table {height:40px; font-size:14px; font-weight:500; line-height:38px; border-radius:7px;}
    &.downTxt {padding-right:52px; background:#fff url(../../images/common/icon_btn_download.svg) calc(100% - 20px) center no-repeat;}

    // 컬러
    &.grey {border: 1px solid #ccc; background: #ccc; color: #fff;}
    &.black {border: 1px solid #969494; background: #444; color: #fff;}
    &.blue {border: 1px solid #005ce6; background: #005ce6; color: #fff;}
    &.line-b {border:1px solid var(--key-blue01); background-color:#fff; color:var(--key-blue01); }
    &.line-g {
        border: 1px solid #ccc; background: #fff; color: #000;
        &.option, &.acting {color:#444;}
    }

    // 이벤트
    &.overK:hover {border: 2px solid #444;}
}


// Form Common
// 콤보박스 공통
.frmCombo {
    position:relative; width:100%; padding:0 20px; max-width:418px;/*가로길이 추후 문의 필요*/ border:1px solid #ccc; border-radius:10px; box-sizing: border-box;

    &.on {
        border-radius:10px 10px 0 0;
    }
    & + input {margin-top: 20px;}

    button {
        position:relative; width:100%; height:64px; font-size:16px; color:#444; font-weight:500; line-height:64px; letter-spacing:-0.4px; text-align:left;

        .arrw {
            position:absolute; width:14px; height:8px; top:50%; right:0; background:url("../../images/common/ico_dropdown.png") top 50% right 0 no-repeat; transform: translateY(-50%); box-sizing:border-box; transition:all 0.4s;
        }

        &.on {
            .arrw {transform:translateY(-50%) rotate(180deg);}
        }
    }

    .drpdwBox {
        display:none; position:absolute; top:64px; left:-1px; width:calc(100% + 2px); background:#fff; border:1px solid #ccc; border-top:0; border-radius:0 0 10px 10px; z-index:99;

        .innerCover {
            width:calc(100% - 40px); margin:0 auto; border-top: 1px solid #ccc; padding:20px 0;
        }
        .innerBox {
            height:auto; max-height:400px; overflow-y:scroll;

            &::-webkit-scrollbar{
                width:4px; background:rgba(0,0,0,0.1);
            }
            &::-webkit-scrollbar-thumb{
                background:rgba(0,0,0,0.2);
            }
            
            ul {
                width:100%;
                li {
                    width:100%;

                    a {
                        display:block; background:#fff; width:100%; height:40px; font-size:16px; color:#444; font-weight:400; line-height:40px; letter-spacing:-0.4px; padding-left:10px; border-radius:5px; transition:all 0.4s;

                        &:hover {
                            background:#f2f9ff; color:#005ce6;
                        }
                    }

                    &.active {
                        a {
                            background:#f2f9ff; color:#005ce6; font-weight:500;
                        }
                    }
                }
            }
        }
    }
}

/*일반 라디오 공통*/
.frmRadio{
    display:flex; flex-flow: row nowrap; align-items: center; justify-content: center; gap:40px;

    label {
        font-size: 18px; display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start; cursor:pointer;
    }
      
    [type="radio"], span {
        vertical-align: middle;
    }
      
    [type="radio"] { 
        appearance: none; border: 1px solid #dcdcdc; border-radius: 50%; width:30px; height:30px; font-size:24px; opacity:1; margin-right:10px; cursor:pointer;
    }

    [type="radio"] + p { 
        display:inline-block; vertical-align: middle; width:max-content; line-height:30px;
    }
      
    [type="radio"]:checked {
        border: 9px solid #005ce6;
    }
    
    [type="radio"]:disabled {
        background-color: lightgray; box-shadow: none; opacity: 1;
    }
    
    [type="radio"]:disabled + p {
        cursor: not-allowed; opacity:.4;
    }
}

/*--------------------------------- 폼 가이드 --------------------------------*/
.cptForm {
    /*checkbox*/
    margin-top: 60px;
    &:first-of-type {margin-top: 0;}
    & + & {margin-top: 120px;}
    .desc { display:inline-block; width:max-content; margin-top: 10px; font-size: 14px; color: #005ce6;}
    .flsTxt { 
        position:relative; padding-left: 20px; display:block; width:max-content; margin-top: 10px; font-size: 14px; color: #fe5f5f; margin-right:10px;
        &::before {
            content: '!'; position:absolute; top:50%; left:0; transform:translateY(-50%); width:16px; height:16px; background:#fe5f5f; border-radius:50%; color:#fff; font-size:12px; font-weight:bold; text-align:center; line-height:16px; padding-right:1px;
        }
    }   
    .frmCheck {
        position: relative; min-height: 30px;

        button {
            width:50px; height:18px; position:absolute; top:50%; right:0; background: url("../../images/common/ryt_arrow.png") top 50% right 0 no-repeat; transform: translateY(-50%); box-sizing: border-box;
        }

        &.standalone {
            input {
                + label {
                    display: block; width: 30px; height: 30px; padding-left: 0;
                }
            }
        }

        input {
            position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; appearance: none; -moz-appearance: none; -webkit-appearance: none; cursor: pointer;

            + label {
                position: relative; padding-left: 42px; font-size: 16px; cursor:pointer;

                &::after {
                    content: ""; display: block; position: absolute; top: 50%; left: 0; width: 30px; height: 30px; background: url("../../images/common/chk_off.png") no-repeat center #dcdcdc; background-size: 14px 10px; border: 1px solid #dcdcdc; border-radius: 4px; transform: translateY(-50%); box-sizing: border-box;
                }

                > span:not(.hidden) {
                    font-size:16px; color:#444; line-height:32px; letter-spacing:-0.4px; font-weight:500;
                }

                > span.hglt {color:#005ce6;}

            }
        }
        
        /* 체크 */
        input:checked:not(:disabled){
            + label::after {
                background: url("../../images/common/chk_off.png") no-repeat center #005ce6; background-size: 14px 10px; border-color: #005ce6;
            }
        }
        
        /* 체크 disabled */
        input:checked:disabled {
            + label::after {
                background: url("../../images/common/chk_off.png") no-repeat center #dcdcdc; background-size: 14px 10px;
            }   
        }

        /* disabled */
        input:disabled {
            + label::after {
                background: url("../../images/common/chk_off.png") no-repeat center #dcdcdc; background-size: 14px 10px;
            }
        }

        /*mini checkbox*/
        &.miniChk {
            input {
                position: absolute; top: 0; left: 0; width: 1px; height: 1px; border: none; appearance: none; -moz-appearance: none; -webkit-appearance: none; cursor: pointer;
    
                + label {
                    position: relative; padding-left: 26px; margin-right: 30px; font-size: 16px; cursor:pointer;
                    &:last-child {margin-right: 0;}
    
                    &::after {
                        content: ""; display: block; position: absolute; top: 50%; left: 0; width: 16px; height: 24px; background: url("../../images/common/ico_check_off.png") no-repeat center; background-size: 16px 12px; transform: translateY(-50%); box-sizing: border-box; border:0;
                    }
    
                    > span:not(.hidden) {
                        font-size:16px; color:#444; line-height:24px; letter-spacing:-0.4px; font-weight:500;
                    }
    
                    > span.hglt {color:#005ce6;}
    
                }
            }
            
            /* 체크 */
            input:checked:not(:disabled){
                + label::after {
                    background: url("../../images/common/ico_check_on.png") no-repeat center; background-size: 16px 12px; border:0;
                }
            }
            
            /* 체크 disabled */
            input:checked:disabled {
                + label::after {
                    background: url("../../images/common/ico_check_off.png") no-repeat center; background-size: 16px 12px;
                }   
            }
    
            /* disabled */
            input:disabled {
                + label::after {
                    background: url("../../images/common/ico_check_off.png") no-repeat center; background-size: 16px 12px;
                }
            }
        }

        /*블랙 bg 화이트 chk*/
        &.blkChk {
            input {
                + label {
                    &::after {border-radius:50%;}
                }
            }
            
            /* 체크 */
            input:checked:not(:disabled){
                + label::after {
                    background: url("../../images/common/chk_off.png") no-repeat center #444; background-size: 14px 10px; border-color: #444;
                }
            }
        }

        /*블루 bg 화이트 chk*/
        &.bluChk {
            input {
                + label {
                    &::after {border-radius:50%;}
                }
            }

            /* 체크 */
            input:checked:not(:disabled){
                + label::after {
                    background: url("../../images/common/chk_off.png") no-repeat center #005ce6; background-size: 14px 10px; border-color: #005ce6;
                }
            }
        }
    }

    /*radio type*/
    .frmRdo {
        position:relative; display:inline-block; vertical-align:middle; margin-right:10px;

        input {
            position:absolute; top:0; left:0; width:100%; height:100%; border:0; appearance:none; -webkit-appearance: none; cursor:pointer; padding:0;

            + label {
                display:flex; justify-content: center; align-items: center; position:relative; height:45px; border:1px solid #ccc; border-radius:10px; color:#444; font-size:16px; text-align:center; box-sizing:border-box; line-height:45px; padding: 0 50px; cursor:pointer; font-weight:400;
            }

            &:checked {
                + label {
                    border:2px solid #005ce6; color:#005ce6; font-weight:500; padding: 0 49px;
                }
            }
        }

        &:last-of-type {margin-right:0;}

        &.lg {
            input {
                + label {
                    height:64px; line-height:64px;
                }
            }
        }
    }

    /*input type text*/
    .frmIpt {
        position:relative;

        .grpCov {position:relative; width:100%;}
        .dataTxt {width: 100%; font-size: 18px; font-weight: 500; color: #000;}
        .btnWrap {
            position: absolute; right: 0; bottom: 15px;
            .iptBtn {
                height: 40px; padding: 0 20px; margin-left: 2px; border-radius: 7px; line-height: 39px; font-size: 14px; color: #fff; display: inline-block;
                &.blue {background: #005ce6;}
                &.black {background: #444;}
            }
        }
        
        &.iptFlx {
            display:flex; flex-flow:row nowrap; align-items:flex-start; justify-content: flex-start; position: relative; flex-wrap: wrap;

            & > span {display:inline-block; width:120px; font-size:14px; color:#444; font-weight:bold; line-height:20px; letter-spacing:-0.35px; margin-right:40px; padding-top: 4px;}
            & > input {width:calc(100% - 160px);padding:0 0 16px 0; }
        }

        input {
            width:100%; font-size:18px; color:#000; font-weight:500; line-height:27px; letter-spacing: -0.53px; padding:14px 0; border:0; border-bottom: 2px solid #dcdcdc;

            &::-webkit-input-placeholder {font-size:18px; color:#ccc; font-weight:500; line-height:27px; letter-spacing:-0.45px;}
            &:focus {outline:0; border-bottom:2px solid #005ce6;}
        }

        /*overlay type*/
        &.ovrTxt {
            .meas {font-size:18px; color:#000; font-weight:400; line-height:28px; letter-spacing: -0.45px; position:absolute; top:12px; right:0;}
        }

        /*overlay type (long word)*/
        &.lng {
            input {padding-right:40px; text-align:right;}
            .formalDataTxt {text-align:right; left:auto; right:40px;}
            .meas {width:33px;}
        }

        /*overlay type (short word)*/
        &.srt {
            input {padding-right:24px; text-align:right;}
            .meas {width:17px;}
        }

        &.wTxt {
            .iptTxt {
                display:flex; flex-flow:row nowrap; align-items:flex-start; justify-content: flex-start; margin-bottom:14px;

                .iptTit {font-size:14px; color:#444; font-weight:bold; line-height:20px; letter-spacing:-0.35px;}
                .required {display:inline-block; widtH:5px; height:5px; background:#005ce0; border-radius:50%;}
            }

            .desc {
                font-size:14px; color:#005ce6; line-height:32px; letter-spacing:-0.35px; font-weight:400; margin-top:10px;

                &.red {color:red;}
            }
        }

        &.half {
            .iptCover {
                position:relative; display:flex; flex-flow:row nowrap; align-items:center; justify-content: center;

                .spanIptCover {/*20240416 수정*/
                    width:calc(50% - 20px); margin-right:20px;
                    &:last-of-type {margin-right:0; margin-left:20px;}

                    &.pswArea {
                        position:relative;

                        &::after {content: ''; position:absolute; top:50%; right:0; transform:translateY(-50%); width:30px; height:30px; background: url(../../images/sub/ico_open_key.svg)50% 50% no-repeat;}
                    }
                }
                span.dash {display:inline-block; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); width:12px; height:2px; background:#000;}

                &.keyCover {position:relative;}
            }
            .halfWrap {
                width: calc(50% - 20px); margin-right: 17px; position: relative; display: inline-block; vertical-align: middle;
                &:last-child {margin-right: 0;}
                input[type='text'] {text-align: right; padding-right: 25px;}
            }
        }
        &.flx {
            display: flex; column-gap: 20px;
            .halfWrap {
                flex: 1 1 auto; display: inline-block; vertical-align: middle; position: relative;
                input[type='text'] {
                    text-align: right; padding-right: 25px;
                    &.two {padding-right: 40px;}
                }
            }
        }

        &.btnTy {
            .iptCover {
                position:relative;

                button {
                    font-size:14px; color:#fff; font-weight:400; line-height:20px; letter-spacing:-0.35px; background:#444; padding:10px 20px; box-sizing:border-box; position:absolute; top:8px; right:0; border-radius:7px;
                }
            }
        }

        &.spEft {
            .resetBtn {display:none; width:27px; height:27px; background:url("../../images/common/ico_reset.png")center center no-repeat; border-radius:50%; position:absolute; top:50%; right:0; transform:translateY(-50%); z-index:9;}
        }
    }

    /*타이틀 영역*/
    .frmTxt {
        // margin-bottom: 10px;

        .required {
            display:inline-block; widtH:5px; height:5px; background:#005ce0; border-radius:50%; vertical-align:text-top;
        }

        &:last-child {margin-bottom: 0;}
        .title {font-size:24px; color:#000; font-weight:bold; line-height:42px; letter-spacing:-0.6px;}
        .subTitle {font-size:18px; color:#444; font-weight:400; line-height:32px; letter-spacing:-0.45px; margin-top:5px;}
        .dirTitle {
            position:relative; display:inline-block; vertical-align:middle; font-size:18px; color:#444; line-height:32px; letter-spacing:-0.45px; font-weight:500;

            + span {
                display:inline-block; vertical-align:middle; padding-left:10px; margin-left:10px; border-left:1px solid #dcdcdc; line-height:15px; font-size:16px; color:#444; font-weight:400; letter-spacing:-0.4px;
            }
        }
        .optTit {font-size:14px; color:#444; font-weight:bold; line-height:32px; letter-spacing:-0.35px;}
    }

    /*validation*/
    .error {
        input[type='text'] {border-bottom:2px solid #fe5f5f; color:#fe5f5f;}
        input[type='radio'] + label {border:2px solid #fe5f5f; color:#fe5f5f;}
        input[type='checkbox'] + label  > * {color:#fe5f5f !important;}
        &.frmCombo {
            border:1px solid #fe5f5f;

            button {color:#fe5f5f;}
            .drpdwBox {border:1px solid #fe5f5f; border-top:0;}
        }
        .dash {background:#fe5f5f !important;}
        
        .optTit {color:#fe5f5f !important;}

        .meas {color:#fe5f5f !important;}
    }

    

    // datepicker
    .datepick {
        display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start;
        .datepickWrap {
            width: 100%;
            .tui-datepicker-input {
                width: 100%; max-width: 420px; height: 65px; border-radius: 10px; border: 1px solid #ccc; padding: 0 0px 0 20px; position:relative;
                input {border: none; border-radius: 10px; padding: 0;font-size: 16px; font-weight: 500; background:transparent; position:relative; z-index:2;}

                &::after {content:''; position:absolute; width: 24px; height: 24px; margin: 0; background: url(../../images/common/ico_calendar.png) center no-repeat; top:50%; right: 20px; transform: translateY(-50%); z-index:1; }
            }
        }
    }

    // Form 레이아웃
    // box레이아웃
    .frmBox{
        width: 100%; border: 1px solid #dcdcdc; border-radius: 15px; padding: 40px;
        &.typeOnly {padding: 30px;}
        & + .frmBox {margin-top: 10px;}
        .boxGroup {
            & > .item {
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                    .imgWrap {margin-bottom:20px}
                }
                
                .insertChkbox {
                    padding: 30px 42px 0 42px;
                    .imgWrap {
                        img {display:block;}
                    }
                    .frmCheck + .frmCheck {margin-top: 20px;}
                }

                /*2024.05.27 추가*/
                span {display:inline-block; vertical-align:middle;}
                span.flag {
                    font-size:12px; color:#fff; font-weight:500; line-height:1; text-align:Center; padding:6px 10px; margin-left:5px; border-radius:15px;
                    &.type01 {background:#00b7fb}
                    &.type02 {background:#01d186}
                    &.type03 {background:#fdc000}
                    &.type04 {background:#fc9448}
                    &.type05 {background:#fe5f5f}
                }
            }
        }
    }

    // Form 나열 리스트 레이아웃
    .frmList {
        width: 100%; margin-top: 40px;
        .listGroup {
            & > .item {
                margin-bottom: 40px;
                &:last-child {margin-bottom: 0;}
                &.inline {
                    margin-bottom: 25px; display: flex; align-items: flex-start; justify-content: flex-start;
                    .frmTxt {width: 130px; margin: 0 30px 0 0; padding-top: 10px;}
                    .frmIpt {
                        width:calc(100% - 160px);
                        // width:450px; margin-right:30px;
                        input {position:relative; z-index:2; background:transparent;}
                        .dataTxt {padding-top: 13px;}
                        .formalDataTxt {position:absolute; top:0; left:0; font-size:18px; color:#ccc; font-weight:500; line-height:27px; letter-spacing:-0.53px; padding:14px 0;}
                    }
                    // .baseData {
                    //     width:calc(100% - 640px); 
                    //     .dataTxt {font-size:18px; color:var(--key-blue01); font-weight:500; line-height:27px; letter-spacing: -0.53px; padding:14px 0;}
                    // }
                }
            }
            .frmTxt {
                margin-bottom: 14px;
                .opTit {margin-bottom: 14px;}
            }
            .radioWrap {
                &.fullType {
                    background:#f3f3f3; padding:60px 120px; 
                    > div {
                        display:block; margin-right:0; margin-bottom:10px;background:#fff; border-radius:15px;

                        &:last-of-type {margin-bottom:0;}
                    }
                }
            }
            .frmCombo + .frmIpt {margin-top: 18px;}
        }
        & + .frmTxt {margin-top: 40px;}

        /*2024.07.31 case 추가 전자계약작성*/
        &.onlineContract {
            .listGroup {
                & > .item {
                    margin-bottom: 40px;
                    &:last-child {margin-bottom: 0;}
                    &.inline {
                        margin-bottom: 25px; display: flex; align-items: flex-start; justify-content: flex-start;
                        .frmTxt {width: 130px; margin: 0 30px 0 0; padding-top: 10px;}
                        .frmIpt {
                            width:700px;
                            input {position:relative; z-index:2; background:transparent;}
                            .dataTxt {padding-top: 13px;}
                            // .formalDataTxt {position:absolute; top:0; left:0; font-size:18px; color:#ccc; font-weight:500; line-height:27px; letter-spacing:-0.53px; padding:14px 0;}
                        }
                        .baseData {
                            width:calc(100% - 860px); border-bottom:2px solid #dcdcdc; padding-left:30px;
                            .dataTxt {font-size:18px; color:var(--key-blue01); font-weight:500; line-height:27px; letter-spacing: -0.53px; padding:14px 0; display:inline-block;}
                        }
                    }
                }
            }
        }
    }

    // Radio 리스트 레이아웃
    .frmRdoList {
        width: 100%;
        .listGroup {
            & > .item {
                margin-bottom: 40px; display: flex; column-gap: 20px; justify-content: space-between; align-items: center;
                &:last-child {margin-bottom: 0;}

                .txt {font-size: 18px; color:#444; word-break: keep-all;}
                .radioWrap {white-space: no-wrap; flex: 1 0 auto; text-align: right;}
            }
        }
    }

    .frmBtnGroup {
        margin-top: 20px;
        .btnArea {
            display: flex; column-gap: 10px;
            &.left {justify-content: flex-start;}
        }
    }

    // 여백
    .comAccorTable + & {margin-top: 60px;}
    .comBtnGroup:last-child {margin-top: 60px;}
    .cptConfirmRadio + & {margin-top: 120px;}

    /*2024.05.27 추가*/
    .aptInfo {
        display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start; margin-top:20px;

        > div {width:250px;}
        > span {display:inline-block; vertical-align: middle; font-size:18px; color:#000; font-weight:500; line-height:28px; margin:0 40px 0 10px;}
    }

    /*2024.06.11 추가 (셀렉트 & 입력칸 조합*/
    .selIptCombo {
        display:flex; flex-flow:row nowrap; align-items: center; justify-content: flex-start; column-gap:20px;
        .frmCombo {
            width:150px;
            .innerBox {overflow:hidden;}
        }
        .iptCover {width: calc(100% - 170px);}
    }
}

/*--------------------------------- 유의사항(추가 알림) 박스 --------------------------------*/
.comAlertBox {
    width: 100%; margin: 40px 0; border: 1px solid #dcdcdc; border-radius: 15px; padding: 30px;
    .alertTit {width: 100%; padding-left: 32px; font-size: 16px; font-weight: 700; color: #444; background: url(../../images/common/ico_notice.png) left center no-repeat;}
    .alertTxt {margin-top: 10px; font-size: 14px; color: #444; line-height: 1.7; word-break: keep-all;}
}

/*--------------------------------- 안내사항 박스 --------------------------------*/
.comInfoBox {
    width: 100%; margin-bottom: 60px; padding: 27px 30px; border-radius: 15px; background: #f2f9ff;
    .infoTxt {
        min-height: 30px; padding-left: 40px; font-size: 14px; color: #444; line-height: 1.7; background: url(../../images/common/ico_notice_round.png) left center no-repeat; display: flex; align-items: center; flex-wrap: wrap;
    }
}

/*--------------------------------- 신분증 업로드 폼 --------------------------------*/
.cptIdentifi {
    .cptForm + & {margin-top: 120px;}
    .comBtnGroup {margin-top: 40px;}
    .identiBox {
        width: 100%; padding: 60px 40px; border-radius: 25px; border: 1px solid #92cdff; text-align: center; background: #f2f9ff;

        .identiTit {
            dt {font-size: 24px; font-weight: 700; color: #444;}
            dd {margin-top: 2px; font-size: 18px; color: #444;}
        }
        .fileArea {
            margin-top: 40px;
            .imgArea {
                width: 290px; height: 200px; margin: 0 auto; position: relative; display: flex; justify-content: center; align-items: center;
                &::before {display: block; content: ""; width: 20px; height: 20px; background: url("../../images/common/ico_area.png") center no-repeat; transform: rotate(90deg); position: absolute; top: 0; left: 0;}
                &::after {display: block; content: ""; width: 20px; height: 20px; background: url("../../images/common/ico_area.png") center no-repeat; transform: rotate(180deg); position: absolute; top: 0; right: 0;}
                
                .imgWrap {
                    width: 245px; height: 155px; display: flex; justify-content: center; align-items: center;
                    &::before {display: block; content: ""; width: 20px; height: 20px; background: url("../../images/common/ico_area.png") center no-repeat; position: absolute; bottom: 0; left: 0;}
                    &::after {display: block; content: ""; width: 20px; height: 20px; background: url("../../images/common/ico_area.png") center no-repeat; transform: rotate(270deg); position: absolute; bottom: 0; right: 0;}
                
                    img {max-width: 100%; max-height: 100%;}
                }
            }
            .fileBox {
                width: 180px; margin: 20px auto 0; position: relative;
                .fileIpt {width: 1px; height: 1px; opacity: 0.01; font-size: 0; position: absolute; top: -1px; left: -1px;}
                .fileIpt + label {width: 100%; height: 40px; border-radius: 7px; border: 1px solid #ccc; text-align: center; line-height: 39px; font-size: 14px; color: #000; background: #fff; display: inline-block; cursor: pointer;}
            }
        }
        .adds {padding-left: 25px; margin: 20px auto 0; font-size: 13px; color: #000; background: url("../../images/common/ico_alert.png") left center no-repeat; display: inline-block;}
        .uploadGuideBtn {margin-top:20px; font-size:14px; color:#444; font-weight:400; text-decoration:underline; line-height:32px; letter-spacing:-0.7px;}
    }
}

/*--------------------------------- 대출 승인 폼 --------------------------------*/
.cptApprove {
    margin-top: 120px;
    &:first-child {margin-top: 0;}
    .alertBox {
        width: 100%; padding: 80px 40px; border: 1px solid #dcdcdc; border-radius: 25px; background: #fcfcfc; text-align: center;
        .alertTit {
            dt {padding-top: 95px; font-size: 24px; color: #000; font-weight: 700; background: url(../../images/common/ico_approve.png) center top no-repeat;}
            dd {margin-top:20px; font-size: 16px; color: #444; line-height: 1.625;}
        }
        .alertAdds {margin-top: 40px; font-size: 16px; font-weight: 500; color: #005ce6;}
        .comBtnGroup {margin-top: 30px;}
    }
}

/*--------------------------------- 대출 거부 폼 --------------------------------*/
.cptDeny{
    @extend .cptApprove;
    .alertBox {
        .alertTit {
            dt {background-image: url(../../images/common/ico_deny.png);}
        }
        .alertAdds {color: #fe5f5f;}
    }
}

/*--------------------------------- 대출 심사 결과 폼 --------------------------------*/
.comResult {
    width: 100%; margin: 20px 0 40px;
    .rsltBox {
        padding: 40px 20px; border-radius: 25px; border: 1px solid #dcdcdc; background: #fcfcfc; 
        .rsltGroup {
            display: flex; align-items: center; justify-content: center;
            .item {
                width: 50%; text-align: center;position: relative;
                &::after {display: block; content: ""; width: 1px; height: 75px; background: #dcdcdc; position: absolute; right: 0; top: 50%; transform: translateY(-50%);}
                &:last-child::after {display: none;}
                .tag {margin-bottom: 15px; font-size: 14px; font-weight: 500; color: #888;}
                .rslt {
                    dt {font-size: 18px; color: #000;}
                    dd {
                        font-size: 28px; color: #000; font-weight: 700;
                        b {color: #005ce6;}
                    }
                }
            }
        }
        
    }
    .adds {
        margin-top: 40px;
        b {width: 100%; font-size: 18px; font-weight: 700; color: #444; display: inline-block; vertical-align: middle;}
        i {width: 100%; font-style: normal; font-size: 16px; color: #444; display: inline-block; vertical-align: middle;}
    }

}


/*-----------------------------------------------탭----------------------------------------------*/

.modTab {
    width:100%; 

    &.type02 {

        .tabBtn {
            width: 100%; display: flex; 
            li {
                width: max-content; padding-bottom:10px; position: relative; margin-right:30px;
                button {width:100%; line-height:30px; margin:0; font-size: 20px; font-weight: 400; color: #ccc;}

                &.active {
                    border-bottom:3px solid #4b9ff9;
                    button {color:#000; font-weight:bold;}
                }

                &:last-of-type {margin-right:0;}
            }
        }
    
        .modTabCont {
            width:100%;
            
            .tabCont {
                display:none; width:100%; padding-top: 30px; flex-flow: row nowrap; align-items: flex-start; justify-content: flex-start; flex-wrap: wrap;
                
                /*연혁 컨텐츠*/
                .leftCont {
                    .imgBox {
                        margin-right:60px;

                        img {margin-top:-26px; margin-left:-30px;}
                    }
                }
    
                .rightCont {
                    .historyList {
                        ul {
                            li {
                                position:relative; padding-left:18px; display:flex; flex-flow: row nowrap; align-items: flex-start; justify-content: flex-start; padding-bottom:30px;
    
                                &:before {content:""; position:absolute; top:0; left:0px; width:1px; height:100%; background:#f3f3f3;}
    
                                &:first-of-type:before {top:14px; height:calc(100% - 14px);}
                                &:last-of-type{
                                    padding-bottom:0;
    
                                    &:before {height:14px;}
                                }
    
                                .yr {
                                    &:before {
                                        content: ""; position:absolute; top:50%; left:-19px; transform:translate(0,-50%); width:4px; height:4px; border-radius:50%; background:#c4c4c4;
                                    }
                                    
                                    font-family:RedHatDisplay; position:relative; font-size:24px; color:#000; line-height:32px; letter-spacing:-0.7px;  margin-right:10px; font-weight:500; width:70px;
                                }
                                .descBox {
                                    .desc {
                                        font-size:16px; color:#000 ; line-height:32px; letter-spacing:-0.4px; font-weight:400; word-break:keep-all;
                                        
                                        > span {display:inline-block; margin-left:23px}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

/*20240510 추가*/
.comWorkSearch {
    width:100%; padding:40px 0; /*윈도우팝업 적용*/
    .workList {
        width:100%; position:relative; margin-top:30px;

        .title {
            font-size:16px; color:#999; font-weight:400; line-height:28px; letter-spacing: -0.4px; margin-bottom:10px;
        }

        > ul {
            width:100%; position:relative; height:373px; overflow-y:scroll;

            &::-webkit-scrollbar{
                width:4px; background:rgba(0,0,0,0.1);
            }
            &::-webkit-scrollbar-thumb{
                background:rgba(0,0,0,0.2);
            }
            .item {
                width:98%; background:#f3f3f3; padding:20px 30px; border-radius:15px; margin-bottom:10px;

                a {
                    display:block; width:100%; height:100%;
                    
                    span {
                        display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start; margin-bottom:10px;
                        
                        span {display:inline-block; width:100px; font-size:14px; color:#000; font-weight:500; line-height:26px; letter-spacing:-0.35px;}

                        p {width:calc(100% - 100px); font-size:14px; color:#000; font-weight:400; line-height:26px; letter-spacing:-0.35px;}

                        &:last-of-type {margin-bottom:0;}
                    }
                }

                &:last-of-type {margin-bottom:0;}

            }
        }
    }

    .noList {
        .txt {font-size:16px; color:#999; font-weight:400; line-height:28px; letter-spacing: -0.4px; margin-top:30px;}
    }
}

/*윤리강령*/
.conContBox {
    width:100%; padding:70px 424px 70px 50px; background:url("../../images/sub/ethics_img.png")center center no-repeat; border-radius:25px; 
    .txt {
        font-size:20px; color:#000; font-weight:400; line-height:32px; letter-spacing: -0.5px; word-break:keep-all;
        .hglt {color:#000; font-weight:500;}
    }
}

.txtBox {
    margin-top:60px;
    .tit {font-size:24px; color:#000; font-weight:bold; line-height:32px; letter-spacing: -0.6px;}
    .desc {margin-top:20px; font-size:16px; color:#000; font-weight:400; line-height:24px; letter-spacing: -0.4px;}
}

.ethicsCont {
    width:100%; position:relative;

    .tit {font-size:24px; color:#000; font-weight:bold; line-height:32px; letter-spacing: -0.6px; margin:40px 0 20px;}

    .ethicDivision {
        width:100%; position:relative; font-size:24px; color:#000; font-weight:500; line-height:1; letter-spacing: -0.6px; padding: 22px 0; text-align:center; background:#fcfcfc; border:4px solid #efefef; border-radius:15px;  margin:68px 0;

        &::before {content:''; width:40px; height:40px; position:absolute; top:-54px; left:50%; transform:translateX(-50%); background:url(../../images/sub/ico_division.png)50% 50% no-repeat;}
        &::after {content:''; width:40px; height:40px; position:absolute; bottom:-54px; left:50%; transform:translateX(-50%); background:url(../../images/sub/ico_division.png)50% 50% no-repeat;}
    }
    .ethicType {
        width:100%; position:relative;

        ul {
            display:flex; flex-flow:row nowrap; align-items: center; justify-content: flex-start;
            li {
                width:calc(50% - 10px); margin-right:20px; padding:40px 20px 40px 40px; border:1px solid #e5e5e5; border-radius:25px;
                &:last-of-type {margin-right:0;}
                p.tyTit {font-size:20px; color:#000; font-weight:500; line-height:32px; letter-spacing: -0.5px; margin-bottom:20px;}
                p.desc {font-size:16px; color:#444; font-weight:400; line-height:24px; letter-spacing:-0.4px;}
                
                &.top01 {background:url(../../images/sub/ico_ethics01.png)top 40px right 40px no-repeat; background-size:50px 50px;}
                &.top02 {background:url(../../images/sub/ico_ethics02.png)top 40px right 40px no-repeat; background-size:50px 50px;}
                &.bot01 {background:url(../../images/sub/ico_ethics03.png)top 43px right 51px no-repeat; background-size:26px 43px;}
                &.bot02 {background:url(../../images/sub/ico_ethics04.png)top 40px right 40px no-repeat; background-size:50px 50px;}
            }

        }

    }
}

/*2024.05.27 대출신청 및 기타 대출 프로세스에서 사용할 로딩이미지*/
.loanLoading {
    position:fixed; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.5); z-index:999; 

    & > div {
        position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); z-index:9999; text-align:center;
        img {display:inline-block; position:relative; width:180px; 
            animation: motion 0.6s linear 0s infinite alternate; top: 0; 
        }
        p {font-size:18px; color:#fff; font-weight:400; line-height:28px; letter-spacing:-0.45px; text-align:center; margin-top:10px;}
    }
}
@keyframes motion {
    0% {top: 0px;}
    100% {top: 15px;}
}


