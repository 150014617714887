.cptBoardList{
    &.nomTop {
        margin-top:0;
    }

    .boardArea{
        .boardTable{
            width:100%; border-top:1px solid #000;

            table{
                width:100%; border-collapse:collapse; border-spacing:0; table-layout:fixed;
                
                thead{
                    td{
                        padding:28px 20px; font-size:16px; font-weight:500; line-height:1.5; color:#000; border-bottom:1px solid #dcdcdc; text-align:center; vertical-align:middle;
                    }
                }
                tbody{
                    td{
                        padding:26px 20px 29px; border-bottom:1px solid #dcdcdc; text-align:center;

                        .num{
                            width:65px; height:35px; font-size:16px; line-height:24px; color:#000; border-radius:17.5px; display:flex; align-items:center; justify-content:center;

                            &.check{
                                font-size:14px; color:#fff; background:#444;;
                            }
                        }
                        .text{
                            width: max-content; max-width: 100%; height:35px; padding:5px 50px 5px 0; font-size:16px; font-weight:400; line-height:24px; color:#000;  
                            overflow:hidden; white-space:nowrap; text-overflow:ellipsis; word-break:break-all; display:block; position:relative; display:inline-block; text-align:center;

                            &.new{
                                ::after{
                                    content:"N"; width:18px; height:18px; font-size:9px; line-height:18px; text-align:center; color:#fff; border-radius:50%; 
                                    background:#ff8484; display:block; position:absolute; top:9px; right:27px;
                                }
                                &.file{
                                    ::before{
                                        right:0;
                                    }
                                }
                            }
                            &.file{
                                ::before{
                                    content:""; width:20px; height:20px; font-size:0; line-height:18px; text-align:center; color:#fff; 
                                    background:url(../../images/common/ico_file.svg) center no-repeat; display:block; position:absolute; top:8px; right:27px;
                                }
                            }
                        }
                        .date{
                            display:inline-block; font-family:"RedHatDisplay"; font-size:16px; font-weight:500; line-height:1.5; color:#888; text-align:center;
                        }
                        .noData {color:#444; font-weight:400;}

                        &.left {text-align:left;}
                        &.center {text-align:center;}
                    }
                }
            }
        }
    }
}

.cptBoardView {
    .boardArea {
        .boardTop {
            width:100%; border-top:1px solid #000; border-bottom:1px solid #dcdcdc; padding:30px 20px;

            .title {
                font-size:24px; color:#000; font-weight:bold; line-height:1.75; letter-spacing:-0.6px; margin-bottom:5px;
            }
            .date {
                font-size:14px; color:#888; font-weight:500; line-height:1;
            }
        }

        .boardCont {
            width:100%; padding:40px 40px 60px 40px; border-bottom:1px solid #dcdcdc;

            p {font-size:16px; color:#000; font-weight:400; line-height:2; letter-spacing:-0.4px;}
        }
    }
}

/* -------------------------------- 게시판 유형 상단 검색 영역 -------------------------------- */
.cptContHeadline {
    width:100%; position:relative; padding-bottom:60px; overflow:hidden;

    &.withBg01 {
        background:url(../../images/common/twin_bg.png)right -54px bottom -145px no-repeat; padding-bottom:100px;
    }

    .title {
        font-size:32px; color:#000; font-weight:bold; line-height:1.31; letter-spacing:-1.6px; text-align:center;
    }

    .subTitle {
        font-size:14px; color:#005ce6; font-weight:400; line-height:1; letter-spacing:-0.7px; text-align:center; margin-top:5px;
    }

    .searchBar {
        width:550px; margin:20px auto 0; position:relative;

        &:first-child{
            margin:0 auto;
        }
        input {
            width:100%; height:65px; line-height:65px; border:2px solid #dcdcdc; border-radius:32.5px; padding: 0 30px; font-size:18px;

            &::placeholder {color:#444;}
        }

        button {
            background: url(../../images/common/ico_search.svg)center center no-repeat; position:absolute; top:50%; right:30px; transform:translate(0,-50%); width:20px; height:22px;
        }
    }

    .descText {
        font-size:14px; color:#005ce6; font-weight:400; letter-spacing:-0.7px; text-align:center; margin-top:10px; line-height:24px;
    }
}

/* -------------------------------- 자주하는질문 아코디언 목록 -------------------------------- */
.cptAccordion {
    width:100%; 

    .accInner {
        position:relative;border-top:1px solid #000;

        li {
            width:100%; position:relative; text-align:left;
            &.noResult {
                padding:60px 40px; border-bottom:1px solid #dcdcdc;

                p {font-size:18px; font-weight:500; text-align:center; line-height:1;}
            }
            button {
                width:100%; display:flex; flex-flow:row nowrap; align-items:center; justify-content: space-between; padding:37px 20px; border-bottom:1px solid #dcdcdc;

                .qTxt {
                    display:flex; flex-flow:row nowrap; align-items:center; justify-content: flex-start; 

                    .qMk {
                        display:inline-block; width:30px; height:30px; line-height:30px; background:#4b9ff9; border-radius:50%; text-align:center; font-family: "RedHatDisplay"; font-size:18px; color:#fff; font-weight:bold; letter-spacing:-0.45px; margin-right:10px;
                    }

                    .title {
                        font-size:16px; color:#000; font-weight:500; line-height:24px; letter-spacing:-0.4px;
                    }
                }

                .accArr {
                    img {
                        transition:all 0.4s;

                        &.on {transform:rotate(180deg);}
                    }
                }

            }

            .contBox {
                display:none; width:100%; background:#f2f9ff; border-bottom:1px solid #dcdcdc; overflow: hidden; padding:40px;

                p {
                    font-size:16px; color:#444; font-weight:400; line-height:2; letter-spacing:-0.4px; word-break:keep-all; display:block;
                    b {font-weight: 500;}
                }
            }
        }
    }
}

/* -------------------------------- 임직원 행동강령 아코디언 -------------------------------- */
.cptAccorCont {
    @extend .cptAccordion;
    .accInner li button {padding: 30px 20px;}
    .accInner li .contBox {padding:0;}
    .accInner li .contBox p {padding: 27px 20px;} /*20240423 추가*/

    & + & {margin-top: 64px;}
}

/* -------------------------------- 썸네일-상세설명 목록 -------------------------------- */
.cptGallDescList {
    width: 100%;

    .gallTable {
        .gallGroup {
            width: 100%; border-top: 1px solid #000;
            .noResult {
                padding:60px 40px; border-bottom:1px solid #dcdcdc;
                p {font-size:18px; font-weight:500; text-align:center; line-height:1;}
            }
            .gallItem {
                & > a {
                    width: 100%; padding: 40px 20px; border-bottom: 1px solid #dcdcdc; display: flex; align-items: center; column-gap: 30px;
                    .thumArea {
                        width: 213px; height: 120px; background: #f5f5f5;
                        img {width: 100%; height: 100%; object-fit: cover;}
                    }
                    .descArea {
                        width: calc(100% - 243px);
                        .gallTit {margin-bottom: 20px; font-size: 20px; color: #000; font-weight: 700; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
                        .gallDesc {font-size: 16px; color: #000; line-height: 28px;  -webkit-line-clamp: 2; -webkit-box-orient: vertical; display: -webkit-box; overflow: hidden; word-break: keep-all;}
                    }
                }
            }
        }
    }
}

/* -------------------------------- 동영상 목록 -------------------------------- */
.cptMovList {
    width: 100%;
    .movList {
        width: 100%; display: flex;flex-wrap:wrap; gap: 40px 22px;

        .item {
            width: calc(33.3333% - 20px);
            .movItem {
                width: 100%;
                .thumArea {
                    width: 100%; height: 185px; background: #f5f5f5; overflow: hidden; text-align: center; border-radius:10px;
                    img {width: 100%; height: 100%; object-fit: cover;}
                }
                .titArea {height: 50px; margin-top: 8px; text-align: left; font-size: 16px; font-weight: 500; color: #000; -webkit-line-clamp: 2; -webkit-box-orient: vertical; display: -webkit-box; overflow: hidden; }
            }
        }
    }
}

/* -------------------------------- 인쇄광고 목록 -------------------------------- */
.cptPostList {
    width: 100%;
    .postList {
        width: 100%; display: flex;flex-wrap:wrap; gap: 40px 20px;

        .item {
            width: calc(25% - 19.75px); 
            .postItem {
                width: 100%;
                .thumArea {
                    width: 100%; height: 330px; background: #f5f5f5; overflow: hidden; text-align: center; border-radius:15px; overflow:hidden;
                    img {width: 100%; height: 100%; object-fit: cover;}
                }
                .titArea {margin-top: 8px; text-align: left; font-size: 16px; font-weight: 500; color: #000; -webkit-line-clamp: 1; -webkit-box-orient: vertical; display: -webkit-box; overflow: hidden; }
            }
        }
    }
}
