.startTerms {
    width:100%; position:relative; font-size:16px; color:#444; line-height:24px; letter-spacing:-0.4px;

    > .title {
        font-size:18px; font-weight:bold;

        &.head {font-size:24px; text-align:center; display:none;}
    }
    > .title:not(:first-of-type) {margin-top:40px;}

    > .description {
        margin-top:10px;
        &.small {
            font-size:14px; color:#666;
        }

        &.head {font-size:16px; text-align:center;}
    }

    .list {
        width:calc(100% - 20px); position:relative; margin-left:20px; margin-top:10px;

        > ul {
            width:100%;
            > li {
                width:100%; margin-top:10px;

                &:first-of-type {margin-top:0;}

                div {
                    ul {
                        li {
                            width:100%; margin-top:5px;

                            &:first-of-type {margin-top:0;}
                        }
                    }
                }
            }
        }
    }

    .tableBox {
        width:100%; margin-top:10px;
        table {
            width:100%; border-collapse: collapse; 
            
            thead {
                tr {
                    th{
                        background:#dcdcdc; text-align:center; padding: 15px 20px; border:1px solid #ccc; font-size:16px; color:#444; font-weight:500;
                    }
                }
            }
            
            tbody {
                tr {
                    td { 
                        background:#fff; text-align:left; padding: 15px 20px; border:1px solid #dfdfdf; font-size:16px; color:#444; font-weight:400;
                    }
                }
            }
        }

    }
}


/*2024.05.27 작성안내 가이드 페이지 추가*/
.infoGuide {
    .infoCapture {
        width:100%; text-align:center;

        img {display:inline-block;}
    }

    .divLine {width:100%; height:1px; background:#dcdcdc; margin:80px 0;}

    .infoTxtBox {
        width:100%; position:relative; display:flex; flex-flow:row nowrap; align-items: flex-start; justify-content: flex-start; padding:40px; background:#f8f8f8; margin-top:60px; border-radius:15px;

        .leftArea {
            span.num {display:inline-block; width:40px; height:40px; border-radius:50%; background:#005ce6; font-size:20px; color:#fff; text-align:center; font-weight:500; line-height:40px; margin-right:10px;}
        }
        .rightArea {
            p.title {
                font-size:18px; color:#000; font-weight:400; line-height:28px; letter-spacing:-0.45px;

                > span {color:#005ce6; font-weight:500;}
            }
            p.desc {font-size:16px; color:#000; font-weight:400; line-height:28px; letter-spacing:-0.4px; margin-top:20px;}
        }

    }
}

/*2024.07.30 신분증 가이드 및 안내 추가*/
.licenseGuide {
    width:100%; position:relative; margin-top:40px; padding:60px 262px; border:1px solid #ccc; border-radius:25px;

    .licTit {font-size:20px; font-weight:bold; color:var(--key-blue01); line-height:32px; letter-spacing:-0.5px; margin-bottom:10px; text-align:center;}
    .licDesc {font-size:16px; color:#444; font-weight:400; line-height:24px; letter-spacing:-0.8px;  text-align:center; margin-bottom:40px;}

}
/*2024.07.30 신분증 가이드 팝업*/
.uploadGuidePopCont {
    p.positive {
        padding-left:35px; font-size:24px; color:#000; font-weight:500; line-height:28px; letter-spacing:-1.2px; position:relative; margin-bottom:20px;
        &::before {content:''; position:absolute; top: 50%; left:0; width:25px; height:25px; background:url(../../images/sub/ico_guide_true.png)center left no-repeat; transform:translateY(-50%);}
    }

    p.negative {
        padding-left:35px; font-size:24px; color:#000; font-weight:500; line-height:28px; letter-spacing:-1.2px; position:relative; margin-bottom:20px; margin-top:60px;
        &::before {content:''; position:absolute; top: 50%; left:0; width:25px; height:25px; background:url(../../images/sub/ico_guide_false.png)center left no-repeat; transform:translateY(-50%);}
    }

    .guideImgBox {background:#f3f3f3; border-radius:25px; padding:60px 45px; text-align:center;}
}

