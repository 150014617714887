/* -------------------------------- 에러 및 공지 -------------------------------- */
.cptError {
    width:100%; margin-top:153px; 

    .errorArea {
        width:1440px; margin:0 auto; padding:134px 20px 0 20px; background:url(../../images/common/img_logo_noti.png) center top no-repeat; 

        .errTitle {
            font-size:24px; line-height:35px; color:#000; font-weight:700; text-align:center; letter-spacing:-0.6px;
        }
        .errMsg {
            margin-top:20px; font-size:16px; line-height:32px; color:#000; font-weight:400; text-align:center; letter-spacing:-0.4px
        }
        .infoBox {
            width:500px; margin:40px auto 0; padding:32px; border:1px solid #dcdcdc; border-radius:15px; background:#fcfcfc;
            .textBox {
                padding-left:35px;
                .text {
                    padding:2px 0; font-size:16px; line-height:24px; color:#000; font-weight:400; text-align:left; display:block;
                }
            }
        }
    }
}

/* -------------------------------- 로딩중 -------------------------------- */
.comLoading{
    width:100%; height:100%; background:rgba(0,0,0,0.5); display:block; position:fixed; left:0; top:0; z-index:1000;

    .loadArea {
        width:100%; height:100%; display:flex; align-items: center; justify-content: center;
    }
    .loadBox {
        width:65px; height:65px; position:relative;
        
        .ball{
            &::before{
                content:""; width:15px; height:15px; border-radius:50%; background:#fff; display:block; transform:scale(0);
                position:absolute; left:0; top:-7.5px; transform-origin: center; animation: loading 1.6s infinite; 
            }
            width:32.5px; height:0px; background:#fff; display:block; position:absolute; left:0; top:32.5px; transform-origin: top right; 
            
            &:nth-child(1){transform: rotate(0deg);
                &::before{
                    animation-delay:0.2s;
                }
            }
            &:nth-child(2){transform: rotate(45deg);
                &::before{
                    animation-delay:0.4s;
                }
            }
            &:nth-child(3){transform: rotate(90deg);
                &::before{
                    animation-delay:0.6s;
                }
            }
            &:nth-child(4){transform: rotate(135deg);
                &::before{
                    animation-delay:0.8s;
                }
            }
            &:nth-child(5){transform: rotate(180deg);
                &::before{
                    animation-delay:1s;
                }
            }
            &:nth-child(6){transform: rotate(225deg);
                &::before{
                    animation-delay:1.2s;
                }
            }
            &:nth-child(7){transform: rotate(270deg);
                &::before{
                    animation-delay:1.4s;
                }
            }
            &:nth-child(8){transform: rotate(315deg);
                &::before{
                    animation-delay:1.6s;
                }
            }
        }
    }
}
@keyframes loading {
    0% {
        transform:scale(0);
    }

    20% {
        transform:scale(1);
    }
  
    100% {
        transform:scale(0);
    }
}



