.cptFooter{
    width:100%; margin-top:150px;

    .footerArea{
        width:100%;

        .footTop{
            width:100%; background:#f5f5f5;

            .topArea{
                width:1400px; padding:40px 0 30px; margin:0 auto;

                .btmMenuGroup{
                    display:flex; flex-wrap:wrap;
                    .btmMenu{
                        padding-right:30px; margin-bottom:10px; position:relative;

                        &::after{
                            content:""; width:1px; height:12px; background:#aaa; display:block; position:absolute; top:5px; right:14px;
                        }
                        &:last-child{
                            padding-right:0;

                            &::after{
                                display:none;
                            }
                        }
                        a{
                            font-size:14px; color:#8b9297; line-height:1.5;
                        }
                    }
                }
            }
        }
        .footBody{
            width:100%; background:#f5f5f5; padding:6px 0 30px;

            .bodyArea{
                /*20240404 추가*/
                &.solo {
                    padding-top:40px;
                }

                width:1400px; margin:0 auto; position:relative; /*2024.06.03 bi 제거하면서 상단 padding 삭제 padding-top:6px;*/

                .copyright{
                    font-size:12px; font-weight:500; color:#444; line-height:1.5;
                }
                .footInfo{
                    margin-top:10px; font-size:12px; font-weight:500; color:#444; line-height:22px;
                }
                .footSite{
                    width:230px; position:absolute; right:0; top:0;

                    .siteGroup{width:100%; height:40px; border:1px solid #dcdcdc; border-radius:20px; background:#fff; overflow:hidden; position:relative;
                        .item{
                            .linkText{
                                padding:0 40px 0 20px; font-size:14px; font-weight:500; color:#111; line-height:38px; white-space:nowrap; display:block;
                            }
                        }

                        &::after {
                            content:""; width:10px; height:10px; background:url(../../images/main/open.svg) center / 10px no-repeat; display:block; transform:rotate(180deg); position:absolute; top:15px; right:20px;
                        }
                        &:hover {
                            height:auto; overflow:auto;

                            &::after {
                                transform:rotate(0deg);
                            }
                            a .linkText{
                                &:hover{
                                    background:rgba(0,0,0,0.05);
                                }
                            }
                        }
                    }
                }
            }
        }
        .footBtm{
            width:100%; background:#f5f5f5; padding:6px 0 30px;

            .btmArea{
                width:1400px; padding-top:30px; margin:0 auto; border-top:1px solid #dcdcdc;

                .text{
                    font-size:30px; color:#444; line-height:1.8;
                }
                .btmBn{
                    margin-top:20px; display:flex; flex-wrap:nowrap;
                    .img{
                        mix-blend-mode: darken;
                    }
                    .text{
                        margin-left:10px; font-size:14px; color:#8b9297; line-height:1.5; align-self:center;
                    }
                }
            }
        }
    }
}