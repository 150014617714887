/* ---------------------------------------- 기본 레이아웃 ---------------------------------------- */
.lytLayout{
    width:100%;

    .layoutArea {
        width:100%;

        .layoutTop{
            width:100%;
        }

        .layoutContainer {
            .layoutMainCont{
                width:100%;
            }
            .layoutSubVisual{
                width:100%;
            }
            .layoutSubCont{
                width:100%; position:relative;
            }
            .subContArea{
                width:1440px; padding:0 20px; margin:0 auto; display:flex; flex-wrap: wrap;
            }
            .layoutNav{
                width:360px; min-width:360px; padding-right:100px;
            }
            .layoutCont{
                width:calc(100% - 360px); margin-top: 80px;
            }
            .layoutFloat {width: 100%;}
        }
        .layoutFooter{
            width:100%;
        }
    }
}

/*--------------------------------- 레이어팝업 --------------------------------*/
.modPopup {
    width: 100%; height: 100vh; position: fixed; top: 0;left: 0;right: 0;bottom: 0; display: flex; align-items: center; justify-content: center; z-index: 999; display: none;
    
    // 팝업동작 기본설정
    .popupDim {width: 100%; height: 100vh; font-size: 0; background: rgba(0,0,0,0.4); position: absolute; top: 0;left: 0;right: 0; bottom: 0; opacity: 0;}
    .popupBox {
        width: 100%; margin: 0 auto; position: relative; display: flex; flex-flow: column; z-index: 1;
        & > * {opacity: 0;}
        &::before {display: block; content: ""; width: 0; height: 0;background: #fff; position: absolute; top: 50%; left: 50%; z-index: -1;}
    }
    .popContArea {
        height: auto; overflow-y: auto;
        &::-webkit-scrollbar{
            width:4px; background:rgba(0,0,0,0.1);
        }
        &::-webkit-scrollbar-thumb{
            background:rgba(0,0,0,0.2);
        }
    }
    
    // 팝업동작 기본설정 - 팝업 열림 동작
    &.popOpen {
        display: flex;
        .popupDim {opacity: 1;}
        .popupBox {
            &::before {animation: popOut 0.15s linear 1 forwards;}
            & > * {animation: opaci1 0.15s linear 0.15s 1 forwards;}
        }
    }
    @keyframes popOut {
        from {width: 0; height: 0;top: 50%; left: 50%;}
        to {width: 100%; height: 100%;top: 0; left: 0;}
    }
    @keyframes opaci1 {
        from {opacity: 0;}
        to {opacity: 1;}
    }

    // 타이틀 있는 팝업
    &.type01 {
        .popupBox {
            max-width: 600px;max-height: 85%; padding: 40px 40px 60px; border-radius: 25px;
            &::before {border-radius: 25px;}
                    
            .popTitArea {
                width: 100%; margin-bottom: 20px; padding-bottom: 20px; border-bottom: 2px solid #dcdcdc; display: flex; justify-content: space-between; align-items: center;
                .tit {
                    font-size: 20px; font-weight: 700; color: #000;
                    b {color: #005ce6;}
                }
                .closeBtn {margin-left: auto; width: 20px; height: 20px; font-size: 0; background: url(../../images/common/ico_close.svg) center no-repeat;}

                &.type02 {border-bottom:0;}
            }

            .popBtnArea {
                width: 100%; padding-top: 40px; display: flex; justify-content: center; align-items: center; column-gap: 10px;
                .popBtn {
                    min-width: 150px; height: 45px; padding: 0 20px; border-radius: 10px; font-size: 16px; color: #fff; text-align: center; line-height: 44px;
                    &.blue { background: #005ce6;}
                }

            }
        }
    }
    
    // 타이틀 없음, X버튼 외부로 빠진 팝업
    &.type02 {
        .popupBox {
            width: auto; max-width: 1040px; max-height: calc(90% - 80px); margin-top: 80px; position: relative;
            .closeBtn {width: 60px; height: 60px; font-size: 0; background: url(../../images/common/btn_close.png) center no-repeat; position: absolute; top: -80px; right: 0;}

            .popContArea {overflow-y:hidden;}
        }

        //case 추가
        &.type02-1 {
            .popupBox {
                padding:80px 50px;
                &::before {border-radius: 25px;}
                .popContArea {overflow-y:scroll;}
            }
        }
    }

    &.noScroll {
        .popContArea {
            height: auto; overflow-y: visible;
        }
    }

    // Alert Popup
    &.alert {
        .popupBox {
            max-width: 500px; padding: 30px 30px 60px; border-radius: 25px;
            &::before {border-radius: 25px;}
            .popTitArea {
                text-align: right;
                .closeBtn {width: 20px; height: 20px; font-size: 0; background: url(../../images/common/ico_close.svg) center no-repeat;}
            }
            .popContArea {
                .popAlert {
                    margin: 40px 0 30px; 
                    p {text-align: center; font-size: 18px; color: #000; line-height: 1.5;}
                }
            }
            .popBtnArea {
                width: 100%;display: flex; justify-content: center; align-items: center; column-gap: 10px;
                .popBtn {
                    min-width: 180px; height: 50px; padding: 0 20px; border-radius: 10px; font-size: 16px; text-align: center; line-height: 49px;
                    &.blue { background: #005ce6;color: #fff;}
                    &.line-k {background: #fff; border: 1px solid #444; line-height: 48px; color: #000;}
                }
            }
        }
    }
}