/* 사이드 메뉴 영역 */
.cptLnb{
    width:100%; margin-top:80px;
    
    .lnbArea{
        width:100%;

        .h2title{
            font-size:24px; font-weight:500; color:#000; line-height:1.5;
        }
        .depth2Group{
            width:100%; margin-top:20px;

            .depth2Menu{
                width:100%; height:65px; border-top:0; transition:height 0.2s, border 0.2s; position:relative; overflow:hidden; border-top:1px solid #ccc;

                .depth2Name{
                    a{
                        width:100%; padding:0 20px 0 0; font-size:16px; font-weight:400; height:65px; line-height:65px; color:#000; display:block;
                    }
                }
                .depth3Group{
                    width:100%; padding:15px 20px; border-top:1px solid #dcdcdc; background:#fbfbfb;

                    .depth3Menu{
                        width:100%; height:50px;

                        .depth3Name{
                            width:100%; height:100%;
                        }
                        .depth3Name a {
                            width:100%; height:100%; font-size:14px; font-weight:400; line-height:1.5; color:#000; display:flex; align-items: center; letter-spacing: -0.9px;

                            // &::before{
                            //     content:"-"; margin-right:5px;
                            // }
                        }

                        &.active {
                            a {color: var(--key-blue01); font-weight:500;}
                        }
                    }
                }
                &.active{
                    
                    .depth2Name{
                        a{
                            color:#005ce6; font-weight:500;
                        }
                    }

                }
                &:first-child{
                    overflow:hidden;
                }
                &:last-child{
                    border-bottom:1px solid #ccc ; overflow:hidden;
                }
                &.add{
                    /*
                    &.active, &.on{
                        .depth3Group{
                            display:block;
                        }
                    }
                    */
                    &::before{
                        content:""; width:14px; height:14px; background:url(../../images/common/ico_open_lnb.svg) center no-repeat; transition:transform 0.2s; transform:rotate(180deg); display:block; position:absolute; right:20px; top:22px;
                    }
                    &.on{
                        &::before{
                            content:""; width:14px; height:14px; background:url(../../images/common/ico_open_lnb.svg) center no-repeat; transform:rotate(0deg); display:block; position:absolute; right:20px; top:22px;
                        }
                    }
                    &.active{
                        &::before{
                            content:""; width:14px; height:14px; background:url(../../images/common/ico_open_lnb.svg) center no-repeat; transform:rotate(180deg); display:block; position:absolute; right:20px; top:22px;
                        }
                        &.on{
                            &::before{
                                content:""; width:14px; height:14px; background:url(../../images/common/ico_open_lnb.svg) center no-repeat; transform:rotate(0deg); display:block; position:absolute; right:20px; top:22px;
                            }
                        }
                    }
                }
            }
        }
    }
}