/* ------------------------------- 메인 상단 카피영역 ------------------------------- */
.cptMainCopy{
    width:100%;

    .copyArea{
        width:1400px; height:150px; padding-top:30px; margin:0 auto; background:url(../../images/main/top_visual.png) calc(100% - 48px) bottom no-repeat; position:relative;

        &::before{
            content:""; width:51px; height:44px; background:url(../../images/main/main_heart.png) center bottom no-repeat; position:absolute; right:193px; top:3px; z-index:10;
            transform-origin: center 60px; transform:rotate(-14deg); animation: actHeart 3s infinite;
        }
        h1{
            font-family:"YangPyeongM"; font-size:50px; font-weight:400; line-height:50px; color:#000; display:block; transform-origin:left; transform:scaleX(0.97);

            .c_blue{
                font-family:"YangPyeongM"; color:var(--key-blue01);
            }
        }
        i{
            margin-top:5px; font-size:16px; font-style:normal; line-height:24px; color:#444; display:block;
        }
    }
}
@keyframes actHeart {
    0% {
        transform:rotate(-14deg);
    }
  
    50% {
        transform:rotate(-34deg);
    }

    100% {
        transform:rotate(-14deg);
    }
}

/* ------------------------------- 메인 비주얼 영역 ------------------------------- */
.cptMainVisual{
    width:100%;

    .visualArea{
        width:1400px; margin:0 auto;

        .visualGroup{
            width:100%; height:560px; display:flex; flex-wrap:nowrap; gap:34px;

            .item{
                width:100%; height:100%; position:relative; cursor:pointer;

                > svg {position:absolute; left:0; top:0; z-index:1;
                    .svgColor {
                        transition:all 0.3s; fill:#f2f9ff;
                    }
                }
                .bnBox{
                    width:100%; height:100%; padding:60px 40px 40px; position:relative; z-index:2;

                    .eyebrow{
                        font-size:18px; font-style:normal; line-height:1.5; color:#000; display:block;
                    }
                    .headline{
                        margin-top:10px; font-family:"YangPyeongM"; font-size:45px; font-weight:400; line-height:1; color:#000; display:block; transform-origin:left; transform:scaleX(0.97);
                    }
                    .infoBox{
                        position:absolute; left:40px; bottom:40px;
    
                        .desc{
                            padding:0 0 20px 30px; font-size:16px; font-style:normal; font-weight:500; color:#000; line-height:1.5; display:block; position:relative;

                            &::after{
                                content:""; width:24px; height:24px; border-radius:50%; background:#005ce6 url(../../images/main/check.svg) center no-repeat; position:absolute; left:0; top:0;
                            }
                        }
                        .tagBox{
                            display:flex; flex-direction:column; gap:6px;;
                            
                            .tag{
                                height:35px; padding:0 20px; font-size:14px; color:#000; line-height:35px; border-radius:17.5px; background:#e1f1ff; display:flex; align-self:flex-start;
                            }
                        }
                    }
                    .link{
                        position:absolute; right:0; bottom:0;
    
                        a{
                            padding:17px 39px 17px 20px; font-size:18px; font-weight:700; line-height:30px; color:#111; display:block; position:relative;

                            &::after{
                                content:""; width:30px; height:30px; border-radius:50%; background:#292b3f url(../../images/main/more_arrow.svg) center no-repeat; position:absolute; top:17px; right:0;
                            }
                        }
                    }
                    &::after{
                       content:""; width:445px; height:220px; display:block; position:absolute; left:0; top:142px;
                    }
                }
                
                &:nth-child(1) .bnBox::after{
                    background:url(../../images/main/vsl01_bg_on.png) center no-repeat; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%); animation: product 1.6s infinite; margin-top:-5px;
                }
                &:nth-child(2) .bnBox::after{
                    background:url(../../images/main/vsl02_bg.png) center no-repeat; position:absolute; top:48%; left:50%; transform:translate(-50%,-50%); animation: product 1.8s infinite; margin-top:-5px;
                }
                &:nth-child(3) .bnBox::after{
                    background:url(../../images/main/vsl03_bg.png) center no-repeat; position:absolute; top:50%; left:47%; transform:translate(-50%,-50%); animation: product 2s infinite; margin-top:-5px;
                }
                &:hover{
                    > svg {
                        .svgColor {
                            fill:#005ce6;
                        }
                    }
                    .bnBox{
                        .eyebrow{
                            color:#fff;
                        }
                        .headline{
                            color:#fff;
                        }
                        .infoBox{
                            .desc{
                                font-weight:400; color:#fff;

                                &::after{
                                    background:#0035b1 url(../../images/main/check.svg) center no-repeat;
                                }
                            }
                            .tag{
                                font-weight:300; color:#fff; background:#0035b1; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                            }
                        }
                    }
                }
            }
        }
    }
}
@keyframes product {
    0% {
        margin-top:0px
    }
  
    50% {
        margin-top:-5px
    }

    100% {
        margin-top:0px
    }
}
/* ------------------------------- 메인 배너 영역 ------------------------------- */
.cptMainBanner{
    width:100%; margin-top:63px;

    .bannerArea{
        width:1400px; margin:0 auto;

        .swiperCont{
            width:1400px; height:230px; border-radius:25px; position:relative; overflow:hidden;

            .swiper-wrapper{

                .swiper-slide:nth-child(1) {background:#ccc;}
                .swiper-slide:nth-child(2) {background:#aaa;}
                .swiper-slide:nth-child(3) {background:#888;}
            }
            .swiper-pagination{
                bottom:15px;
                .swiper-pagination-bullet{
                    width:5px; height:5px; margin:0 2.5px; background:#cbcbcb;
                    &.swiper-pagination-bullet-active{
                        background:#444;
                    }
                }
            }
        }
    }
}
/* ------------------------------- 메인 콘텐츠 영역 ------------------------------- */
.cptMainContbox{
    width:100%; margin-top:60px;

    .contArea{
        width:1400px; margin:0 auto; display:flex; flex-wrap:nowrap; 

        /* 배너1, 배너2 */
        .banner{
            height:341px; padding:30px; border:1px solid #dcdcdc; border-radius:25px; position:relative;

            .title{
                font-size:24px; font-weight:500; color:#000;
                > span {font-size:16px; color:#000; font-weight:400; line-height:1;}
            }
            .cont{
                margin-top:20px; font-size:14px;
                > span {font-size:14px; color:#000; font-weight:400; line-height:22px; letter-spacing:-0.35px;}
            }
            .tagBox{
                display:flex; flex-direction:column; gap:6px;

                .tag{
                    height:35px; padding:0 20px; font-size:14px; color:#000; line-height:35px; border-radius:17.5px; background:#f3f3f3; display:flex; align-self:flex-start;
                }
            }

            &.type01{
                width:417px; background:url(../../images/main/bn01_Bg.png) calc(100% - 30px) calc(100% - 18px) no-repeat;

                a.link{
                    height:100%; display:block;
                }
            }
            &.type02{
                width:271px; margin-left:20px; background:url(../../images/main/ico_youtube.png) calc(100% - 30px) calc(100% - 18px) no-repeat;

                a.link{
                    display:block; width:100%; height:100%;
                }
            }
        }

        /* 자주하는 질문 */
        .qnaArea{
            width:652px; height:341px; margin-left:40px;

            .title{
                font-size:18px; font-weight:700; color:#000; line-height:27px; display:flex;

                a.link{
                    width:20px; height:20px; margin-left:5px; font-size:0; border-radius:50%; background:#444 url(../../images/main/more_arrow.svg) center / 11px no-repeat; display:inline-block; align-self:center;
                }
            }
            .cont{
                margin-top:10px;

                .qnaGroup{
                    width:100%; display:flex; flex-wrap:nowrap; flex-direction:column; gap:10px;
                    .item{
                        width:100%; height:60px; padding:15px 64px 15px 20px; border-radius:15px; background:#f3f3f3; transition:all 0.3s; overflow:hidden; cursor:pointer;

                        .qnaBox{
                            padding-left:40px; position:relative;

                            &::after{
                                content:""; width:30px; height:30px; border-radius:50%; background:#4b9ff9 url(../../images/main/q_text.png) center no-repeat; display:block; position:absolute; left:0; top:0; transition:width 0.3s, height 0.3s;
                            }
                            .quest{
                                font-size:16px; font-weight:500; color:#444; line-height:30px;
                            }
                            .answer{
                                height: auto; margin-top:10px; font-size:14px; color:#444; line-height:24px; overflow-y:auto; visibility:hidden;

                                &::-webkit-scrollbar{
                                    width:4px; background:rgba(0,0,0,0.1);
                                }
                                &::-webkit-scrollbar-thumb{
                                    background:rgba(0,0,0,0.2);
                                }
                            }
                        }
                        &.on{
                            height:165px; padding:25px 64px 25px 30px; border-radius:25px; background:#ebf5ff;
                            
                            .qnaBox{
                                padding-left:75px; display: flex; flex-flow: column; height: 100%;

                                &::after{
                                    width:55px; height:55px; background:#4b9ff9 url(../../images/main/quest.png) center / cover no-repeat;
                                }
                                .quest{
                                    font-size:18px; font-weight:700; color:#000; flex: 1 0 auto;
                                }
                                .answer{
                                    visibility:visible; flex: 0 1 auto;
                                }
                            }
                            &:before{
                                
                            }
                        }
                    }
                }
            }
        }

        /* 공지사항 */
        .noticeArea{
            width:1054px; height:274px;

            .title{
                font-size:18px; font-weight:700; color:#000; line-height:27px; display:flex;

                a.link{
                    width:20px; height:20px; margin-left:5px; font-size:0; border-radius:50%; background:#444 url(../../images/main/more_arrow.svg) center / 11px no-repeat; display:inline-block; align-self:center;
                }
            }
            .cont{
                margin-top:10px;

                .noticeGroup{
                    display:flex; flex-wrap:nowrap; gap:20px;

                    .item{
                        width:338px; height:237px;

                        a.link{
                            width:100%; height:100%; display:block;
                        }
                        .notiBox{
                            padding:20px;

                            .notiTitle{
                                height:60px; font-size:20px; font-weight:700; color:#000; line-height:1.5;

                                i{
                                    width:18px; height:30px; margin-top:-5px; margin-left:5px; font-size:0; background:url(../../images/main/new.svg) center no-repeat; display:inline-block; vertical-align:middle;
                                }
                            }
                            .notiCont{
                                margin-top:40px;
    
                                .cont{
                                    height:50px; font-size:16px; color:#444; line-height:1.5; display:block;
                                }
                                .date{
                                    margin-top:30px; font-size:14px; color:#999; line-height:30px; display:block;
                                }
                            }
                        }
                    }
                     
                }
            }
        }
        
        /* 고객상담센터 */
        .contactBox{
            width:306px; height:274px; margin-left:40px; padding:30px; border-radius:25px; background:#f4f6fa url(../../images/main/call.png) calc(100% - 30px) calc(100% - 30px) no-repeat; 

            .title{
                font-size:24px; font-weight:500; color:#232323; line-height:1.5;
            }
            .phone{
                margin-top:10px; font-family:"RedHatDisplay"; font-size:34px; font-weight:700; color:#232323; line-height:45px;
            }
            .time{
                font-size:16px; font-weight:500; color:#232323; line-height:1.5;
            }
        }

    }
}

/*2024.07.08 쿠키 바 추가*/
.cookieBar {
    position:fixed; bottom:0; left:0; width:100%; background:#fff; padding:30px 0; box-shadow: 0 -5px 15px 0 rgba(0, 0, 0, 0.1); z-index:99999;
    .inner {
        width:1400px; margin:0 auto; display:flex; flex-flow: row nowrap; align-items: center; justify-content: space-between;

        .text {
            width:60%;
            
            p {
                font-size:16px; color:#444; font-weight:400; line-height:26px; letter-spacing:-0.4px;
                
                span {text-decoration:underline;}
            }
        }

        .buttons {
            width:40%; display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-end; column-gap: 7px;

            > .sett {width:140px; font-size:16px; color:#000; font-weight:400; line-height:50px; text-align:center; border:1px solid #444; background:#fff; border-radius:10px;}
            > .act {width:140px; font-size:16px; color:#fff; font-weight:400; line-height:50px; text-align:center; border:1px solid var(--key-blue01); background:var(--key-blue01); border-radius:10px;}

        }

    }
}
