/* -------------------------------- 일반 소개페이지 -------------------------------- */
.cptContIntro {
    .comBtnGroup {margin-top: 20px;}
    .introTit {
        margin-top: 60px; font-size: 20px; font-weight: 700; color: #000;
        &:first-child {margin-top: 0;}
        &.diffmTop {margin-top:80px;}
    }
    .introDesc {
        margin-top: 10px; font-size: 16px; color: #000; line-height: 28px; word-break: keep-all;

        & + .comImgDesc {margin-top: 20px;}
    }

    // 사랑나눔
    .introCate {
        margin-top: 40px; display: flex; column-gap: 21px;
        .item {
            border-radius: 45px; padding: 30px; text-align: center; width:calc(33.3333% - 14px); border:2px solid #e5e5e5;
            p.title {font-size: 28px; color: #000; font-weight: 700; margin-bottom:44px;}
            p.desc {font-size: 16px; font-weight: 500; color: #000; margin-top:52px; margin-bottom:20px;}
            img {display:block; width:100%;}
            .cateBtn {width: 178px; height: 45px; border-radius: 22.5px; text-align: center; line-height: 44px; font-size: 16px; font-weight: 500; color: #fff; background: #005ce6;  display: inline-block;}
        }
    }

    // 인재영입 word 컨텐츠
    .wordSimbol {
        margin-top: 25px;
        .wordGroup {
            width: 100%; display: flex; align-items:center; justify-content: space-between; column-gap: 20px; margin-bottom:80px;
            .item {
                width: calc(25% - 20px); padding: 80px 0 50px; border-radius: 45px; border: 1px solid #dcdcdc; text-align: center; position: relative;
                .desc {
                    dt {font-size: 16px; color: #000; margin-top:68px; font-weight:500;}
                    dd {margin-top: 20px; font-size: 14px; color: #000; line-height: 1.4;}
                }
            }
        }
    }

    // 인재영입 아이콘 설명문
    .iconDescWrap {
        width: 100%; margin-top: 40px; display: flex; align-items: flex-start; column-gap: 60px ; margin-bottom:80px;

        .iconKey {
            width: calc(50% - 30px); 
            .tit {margin-bottom: 20px; font-size: 20px; font-weight: 700; color: #000;}
            .keyGroup {
                width: 100%; display: flex; column-gap: 20px;
                .item {
                    text-align: center; 
                    img {max-width: 100%; display:block;}
                    p {margin-top: 20px; font-size: 16px; color: #000; line-height:32px; font-weight:500;}
                }
            }

        }
    }

    // 이미지커버 카드
    .imgCoverDesc {
        width: 100%; margin: 20px 0 60px;
        .imgGroup {
            width: 100%; display: flex; flex-flow:row wrap; column-gap:19px;
            .item {
                position:relative; width:334px; height:230px; background:#fff6c7; margin-bottom:20px; border-radius:25px; overflow:hidden; padding:50px 30px; transition:all 0.4s;

                &::before {content: ''; position:absolute; bottom:0; right:0; background:url(../../images/sub/recruit_card01.png)0 0 no-repeat; width:240px; height:230px; z-index:1}
                &.item02 {
                    background:#f5f1ff;
                    &::before {background:url(../../images/sub/recruit_card02.png)0 0 no-repeat; width:167px; height:230px;}
                }
                &.item03 {
                    background:#eff3ff;
                    &::before {background:url(../../images/sub/recruit_card03.png)0 0 no-repeat; width:170px; height:230px;}
                }
                &.item04 {
                    background:#fff2fa;
                    &::before {background:url(../../images/sub/recruit_card04.png)0 0 no-repeat; width:156px; height:230px;}
                }
                &.item05 {
                    background:#e7f9e9;
                    &::before {background:url(../../images/sub/recruit_card05.png)0 0 no-repeat; width:183px; height:230px;}
                }
                &.item06 {
                    background:#effbff;
                    &::before {background:url(../../images/sub/recruit_card06.png)0 0 no-repeat; width:250px; height:230px;}
                }
                &.item07 {
                    background:#f9ffef;
                    &::before {background:url(../../images/sub/recruit_card07.png)0 0 no-repeat; width:191px; height:230px;}
                }
                &.item08 {
                    background:#ffeedb;
                    &::before {background:url(../../images/sub/recruit_card08.png)0 0 no-repeat; width:245px; height:230px;}
                }
                &.item09 {
                    background:#effbff;
                    &::before {background:url(../../images/sub/recruit_card09.png)0 0 no-repeat; width:191px; height:230px;}
                }

                > .title {font-size:22px; color:#000; font-weight:500; line-height:32px; letter-spacing:-0.6px; position:relative; z-index:2;}
                // > .engTit {font-size:14px; color:#000; font-weight:500; line-height:1; letter-spacing:-0.35px; opacity:0.5; margin-top:5px;} 
                > .desc {font-size:16px; color:#000; font-weight:500; line-height:28px; letter-spacing:-0.4px; margin-top:10px; position:relative; z-index:2;}
                > .hoverDesc {font-size:16px; color:#fff; font-weight:500; line-height:28px; letter-spacing:-0.4px; margin-top:10px; position:relative; z-index:2; display:none; word-break:keep-all;}
                &:hover {
                    background:#4b9ff9;
                    &::before {display:none;}
                    > p {color:#fff !important;}
                    > .desc {display:none;}
                    > .hoverDesc {display:block;}
                }
            }
        }
    }

    .recruit_msg {
        width:100%; position:relative; padding:37px 40px 36px 40px; background:#fcfcfc; border:1px solid #dcdcdc; border-radius:25px; display:flex; flex-flow:row nowrap; align-items: center; justify-content: space-between;
        p.message {
            position:relative; padding-left:69px; font-size:16px; color:#000; font-weight:400; line-height:24px; letter-spacing:-0.4px;
            &::before {content: ''; position:absolute; top:50%; left:0; width:55px; height:55px; background: url(../../images/sub/mail_img.png)50% 50% no-repeat; transform:translateY(-50%);}
        }
        > a {
            font-size:16px; color:#000; font-weight:500; line-height:32px; letter-spacing:-0.4px; position:relative; padding-right:29px;
            &::after {content: ''; position:absolute; top:50%; right:0; width:24px; height:24px; background:url(../../images/common/ico_download_blk.png)50% 50% no-repeat; transform:translateY(-50%);}
        }

    }
}


/* -------------------------------- 상세컨텐츠 -------------------------------- */
.cptDescription {
    .descWrap {
        .parag {
            margin-bottom: 40px; display: flex; flex-wrap: wrap; row-gap: 10px;
            &:last-child {margin-bottom: 0;}
            & > * {width: 100%;}
        }

        .descTit {font-size: 18px; font-weight: 700; color: #000; }
        
        .descPara {
            font-size: 16px; color: #000; line-height: 1.5;
            .dtlTit {width: 100%; font-weight: 500; display: inline-block;}
        }
        
        .descTable {
            width: 100%;

            table {
                width: 100%; border-top: 1px solid #dcdcdc; border-collapse: collapse;
                th {
                    padding: 15px 0; border-bottom: 1px solid #dcdcdc;border-right: 1px solid #dcdcdc; text-align: center; font-size: 16px; color: #000; font-weight: 400; background: #f5f5f5;
                    &:last-child {border-right: none;}
                }
                td {
                    padding: 15px 0; border-bottom: 1px solid #dcdcdc;border-right: 1px solid #dcdcdc; text-align: center; font-size: 16px; color: #000;
                    &:last-child {border-right: none;}
                    &.bdR {border-right: 1px solid #dcdcdc;}
                }
            }
        }

        .descNumb {
            .numItem {
                margin-bottom: 20px; padding-left: 15px;
                &:last-child {margin-bottom: 0;}

                .numTit {margin-left: -15px; font-size: 16px; font-weight: 500; color: #000;}
            }
        }
        .descAdds {
            margin-top: 5px; font-size: 14px; color: #000; line-height: 1.4; display: block;
        }
        .downBtnGroup {
            margin-top: 10px; display: flex; column-gap: 10px;
            .downBtn {width: 240px; height: 50px; padding: 0 20px; border: 1px solid #000; line-height: 49px; font-size: 14px; color: #000; background: url(../../images/common/ico_download.svg) calc(100% - 20px) center no-repeat; display: inline-block;}
        }
    }
}

/* -------------------------------- 대출 소개페이지 -------------------------------- */
.cptLoanInfo {
    .loaninfoBox {
        width: 100%; padding: 100px 100px 60px; border-radius: 35px; background: #f2f9ff;
        .loanTitArea {
            width: 100%; padding-right: 380px; background: right top no-repeat;
            &.baro300 {
                background-image: url("../../images/sub/loan_baro300.png");
            }
            &.estate {
                background: url("../../images/sub/loan_estate.png")center right 10px no-repeat;
            }
            &.car {
                background: url("../../images/sub/loan_car.png")center right no-repeat;
            }
            
            .loanTit {
                i {font-size: 18px; font-weight: 500; color: #444; display: block; font-style: normal;}
                b {font-size: 50px; color: #000; font-weight: 500; font-family: "YangPyeongM"; letter-spacing: -0.075em; display: block;}
            }
            .tagGroup {
                margin-top: 15px; display: inline-flex; align-items: center; justify-content: center; column-gap: 7px;
                .tag {height: 40px; padding: 0 20px; border-radius: 20px; line-height: 39px; font-size: 14px; font-weight: 500; color: #fff; background: #4b9ff9;}
            }
    
            .summaryGroup {
                margin-top: 60px; display: flex; align-items: center; justify-content: flex-start;
                .item {
                    padding: 0 40px; position: relative;
                    &::after {display: block; content: ""; width: 1px; height: 55px; background: #dcdcdc; position: absolute; right: 0; top: 50%; transform: translateY(-50%);}
                    &:last-child::after {display: none;}
                    &:first-child {padding-left: 0;}
                    &:last-child {padding-right: 0;}
    
                    dl {
                        text-align: center;
                        dt {padding-top: 55px; font-size: 14px; font-weight: 500; color: #888; background: center top no-repeat;}
                        &.period {
                            &.per36 dt {background-image: url("../../images/sub/loan_ico10.png"); background-size:45px 45px;}
                            dt {background-image: url("../../images/sub/loan_ico01.png");}
                        }
                        &.max dt {background-image: url("../../images/sub/loan_ico02.png");}
                        &.step dt {background-image: url("../../images/sub/loan_ico03.png");}
                        dd {font-size: 16px; font-weight: 500; color: #000;}
                    }
                }
            }
        }
    
        .loanSumm{
            margin-top: 40px;
            
            .btnArea {
                margin-top: 40px; text-align: center;
                .loanBtn {min-width: 320px; height: 85px; padding: 0 50px; border-radius: 42.5px; font-size: 24px; color: #fff; text-align: center; line-height: 84px; background: #005ce6; display: inline-block;}
            }
    
            .needsGroup {
                display: flex; column-gap: 10px;
                .item {
                    width: 50%; min-height: 70px; padding: 0 20px; border-radius: 15px; border: 1px solid #f3f3f3; background: #fff; display: flex; align-items: center; justify-content: flex-start; column-gap: 10px;
                    .ndIcon {display: inline-block;}
                    .needs {font-size: 16px; font-weight: 500; color: #000; display: inline-block;}
                }
            }
        }
    }

    .loanDetail {
        margin-top: 60px; padding-top: 60px; border-top: 1px solid #dcdcdc;
        .dtlItem {
            margin-bottom: 40px;
            &:last-child {margin-bottom: 0;}
            .detail {
                display: flex; align-items: top;
                dt {
                    width: 205px; padding: 0 40px; font-size: 18px; font-weight: 700; color: #000;
                }
                dd {
                    width: calc(100% - 205px);
                    .desc {
                        font-size: 16px;color: #000; line-height: 2;
                        .unit {
                            width: 100%;display: inline-block;
                        }
                        b {font-weight: 500;}
                        .tBlue {color: #005ce6;}
                    }
                    .adds {
                        margin-top: 10px;
                        .txt {
                            font-size: 14px; color: #444; margin-bottom: 7px;
                            &:last-child {margin-bottom: 0;}
                        }
                    }
                }
            }

        }
    }

}

/* -------------------------------- 대출 소개 플로팅 -------------------------------- */
.cptLoanFloat {
    width: 100%; margin: 20px 0 -150px; height: 130px; position: relative;

    .floatWrap {
        width: 100%;max-width: 1400px;  position: fixed; bottom: -130px; left: 50%; transform: translateX(-50%); transition: all 0.2s; z-index: 99;
        
        // &::before {display: block; content: ""; width: 100%; height: 128px; border-radius: 25px 25px 0 0; background: rgba(255,255,255,0.5); backdrop-filter: blur(3px); position: absolute; bottom: 0; left: 0; z-index: -9;}
        &.floatOn {bottom: 0;}
        &.floatNone {position: absolute; bottom: 0;}

        // .floatBox {
        //     width: 100%; height: 130px; padding: 0 40px 0 60px; border: 1px solid #dcdcdc; border-bottom: none; border-radius: 25px 25px 0 0; display: flex; justify-content: space-between; align-items: center; 
        
        //     .titArea {font-size: 28px; font-weight: 500; color: #000;}
        //     .btnArea {
        //         text-align: right;
        //         .loanBtn {min-width: 230px; height: 75px; padding: 0 20px; border-radius: 37.5px; text-align: center; line-height: 74px; font-size: 20px; color: #fff; background: #005ce6; display: inline-block; }
        //     }
        // }
    }
    
}

/* -------------------------------- 회사소개 - 인사말 -------------------------------- */
.cptGreeting {
    .greetBox {
        width: 100%; padding: 84px 300px 30px 60px; background: #f9fdff url(../../images/sub/greet_master.png) right 50px bottom no-repeat; border:2px solid #d2f2ff; border-radius:25px; 
        .txt {font-size: 22px; font-weight: 400; color: #000; line-height: 32px; letter-spacing:-0.55px;}
        .hashWord {
            display:flex; flex-flow:row nowrap; align-items: center; justify-content: flex-start; gap:10px; margin-top:20px;
            span {font-size:18px; color:#005ce6; font-weight:400; letter-spacing:-0.45px;}
        }
        .sign {
            margin-top: 30px; text-align: right;
            b {font-size: 16px; color: #000; font-weight: 400; display: inline-block; vertical-align: -webkit-baseline-middle;}
            img {margin-left: 10px; display: inline-block; vertical-align: middle;}
        }
    }

    .greetTxt {margin-top: 60px; font-size: 16px; color: #000; line-height: 2; word-break: keep-all;}
}

/* -------------------------------- 회사소개 - CI/BI -------------------------------- */
.cptCI {
    .modTab + .comSubTit {margin-top: 100px;}
    .imgArea {
        width: 100%; margin-bottom: 40px; display: flex; flex-wrap: wrap; gap:40px;
        &:last-child {margin-bottom: 0;}
        .imgWrap {
            width: calc(50% - 20px); position: relative;
            &.full {width: 100%;}

            img {max-width: 100%;}
            .imgTxt {
                margin-top: 10px; font-size: 20px; font-weight: 700; color: #000;
                &.light {font-weight: 400; font-size: 16px;}
                &:first-child {margin-top: 0; margin-bottom: 10px;}
            }
        }
        
        &.smallDesc {
            margin-top: -30px; row-gap: 20px;

        }
        
    }

    .txtArea {
        width: 100%; margin-bottom: 40px;
        &:last-child {margin-bottom: 0;}
        .txtTit {margin-bottom: 10px; font-size: 20px; font-weight: 700; color: #000;}
        .eachDesc {
            margin-bottom: 20px;
            &:last-child {margin-bottom: 0;}
            dt {margin-bottom: 10px; font-size: 20px; font-weight: 700; color: #000;}
            dd {font-size: 16px; color: #000; word-break: keep-all;}
        }
    }

    .modTab {
        margin-top: 80px;
        .modTabCont {
            .tabCont {padding-top: 60px;}
        }
    }
}


/* -------------------------------- 회사소개 - 오시는 길 -------------------------------- */
.cptLocation {
    width: 100%; margin-top: 60px;
    &:first-child {margin-top: 0;}
    .mapWrap {width: 100%; height: 500px; border-radius: 25px; overflow: hidden;}
    .infoBox {
        width: 100%; border-top: 1px solid #000; border-bottom: 1px solid #dcdcdc; padding: 16px 0;
        .txt {
            font-size: 16px;color: #000;line-height: 2;
            img {margin: 5px 5px 5px 0; display: inline-block; vertical-align: middle;}
        }
    }
    .comBtnGroup{
        margin-top: 20px;
    }
    .comContTit {
        margin: 60px 0 9px;
        &:first-child {margin-top: 0;}
    }
    & + .cptTable {margin-top: 65px;}

}

// 고객센터
/* -------------------------------- 광고자료(이미지) -------------------------------- */
.cptMediaCont {
    line-height: 1;
    iframe {width: 1040px; height: 585px;}
    .imgWrap {width: 100%; height: calc(90vh - 80px); line-height: 0;}
    img { max-width: 100%; max-height: 100%; object-fit: contain;}
}

// 나의 대출조회
/* -------------------------------- 데이터 없음 -------------------------------- */
.cptNoneData {
    width: 100%;
    .cptContractList + & {margin-top: 120px;}
    .dataBox {
        width: 100%; padding: 80px 40px; border: 1px solid #dcdcdc; border-radius: 25px; text-align: center;
        .imgArea {}
        .txtArea {
            margin-top: 25px;
            .alert {font-size: 24px; font-weight: 700; color: #000;}
            .adds {margin-top: 15px; font-size: 16px; color: #444;}
        }

        .comBtnGroup {margin-top: 30px;}
    }

    &.noline {
        .dataBox {border: none; padding: 20px 40px 80px;}
    }
}

/* -------------------------------- 심사현황 -------------------------------- */
.cptStateBox {
    width: 100%;
    .stateGroup {
        .item {
            margin-bottom: 40px; padding-bottom: 40px; border-bottom: 1px solid #dcdcdc;
            &:last-child {margin-bottom: 0; padding-bottom:0; border-bottom: none;}
        }

        .stateBox {
            width: 100%; padding: 55px 180px 60px 60px; border-radius: 25px; background: #f7f8fc;
            
            .itemInfo{
                margin-bottom: 40px;
                .name {font-size: 24px; font-weight: 500; color: #000;}
                .date {
                    margin-top: 5px;font-size: 16px;color: #444;
                    b {font-weight: 500;}
                    i {font-style: normal;}
                }
            }

            .stateBar {
                .barWrap {
                    width: 100%; height: 7px; border-radius: 3.5px; background: #fff; box-shadow: 0 3px 7px 0 rgba(68, 68, 68, 0.04); overflow: hidden;
                    .bar {height: 7px; border-radius: 3.5px; font-size: 0; background: #005ce6;}
                }
                .stateTit {
                    margin-top: 15px; display: flex; align-items: center; justify-content: flex-start;
                    .state {width: 25%; font-size: 18px; color: #ccc; font-weight: 700; text-align: right;}
                }
                &.step01{
                    .bar {width: 25%;}
                    .state:nth-child(1) {color: #000;}
                }
                &.step02{
                    .bar {width: 50%;}
                    .state:nth-child(1) {color: #888;}
                    .state:nth-child(2) {color: #000;}                
                }
                &.step03 {
                    .bar {width: 75%;}
                    .state:nth-child(1),.state:nth-child(2) {color: #888;}
                    .state:nth-child(3) {color: #000;}
                }
                &.step04 {
                    .bar {width: 100%;}
                    .state:nth-child(1),.state:nth-child(2),.state:nth-child(3) {color: #888;}
                    .state:nth-child(4) {color: #000;}
                }
                // &.deny {
                //     .bar {background: #fe5f5f;}
                //     .state:nth-child(2) {color: #fe5f5f;}
                // }
            }
        
            .stateNow {
                margin-top: 60px;
                .now {
                    font-size: 18px; font-weight: 500; color: #000;
                    b {color: #005ce6; font-weight: 700;}
                }
                .adds {
                    margin-top: 10px; font-size: 16px; color: #666; line-height: 1.625;
                    .deny {font-size: 18px; font-weight: 500; color: #fe5f5f;}
                    .more {width: 100%; margin-top: 10px; font-size: 14px; color: #005ce6; display: inline-block;}
                }
            }
            .comBtnGroup {margin-top: 40px;}
        }
    }
    .comBtnGroup {margin-top: 60px;}
}

/*--------------------------------- 대출상세 on/off 목록 --------------------------------*/
.cptLoanDetail {
    .cptForm + & {margin-top: 60px;}
    .cardBtnGroup {
        .cardGroup {
            width: 100%; padding-bottom: 20px; display: flex; column-gap: 19px; align-items: stretch; justify-content: flex-start; overflow-x: auto;
            &::-webkit-scrollbar {width: 5px; height: 5px;}
            &::-webkit-scrollbar-thumb {background-color: rgba(170, 204, 255, 0.5);border-radius: 2.5px;}
            &::-webkit-scrollbar-track {background-color: rgba(255,255, 255, 0.5);border-radius: 2.5px;}
            

            .cardItem {
                width: 334px; height: auto; flex: 0 0 334px;
                .card {
                    width: 100%; height: 100%; padding: 37px 40px 115px; border: 1px solid #dcdcdc; border-radius: 25px; text-align: left; display: flex; flex-wrap: wrap; position: relative;
                    .tit {font-size: 20px; font-weight: 700; color: #000; word-break: keep-all;}
                    .infoGroup {
                        position: absolute; bottom: 40px; left: 40px;
                        .info {
                            margin-top: 5px; font-size: 16px; color: #444;
                            b {font-weight: 500; display: inline-block;}
                            i {margin-left: 7px; font-style: normal; display: inline-block;}
                        }
                    }

                    &.nowOn {
                        border: 2px solid #bfdfff; background: #f2f9ff; padding: 36px 39px 114px;
                        .infoGroup {bottom: 39px; left: 39px;}
                    }
                }
            }
        }
    }

    .loanDtGroup{
        margin-top: 50px;
        .dtItem {
            display: none;
            .comContTit {
                margin-top: 60px;
                &:first-child {margin-top: 0;}
            }

            &.on {display: block;}
        }
        
    }

    .dtArea {
        & > .comBtnGroup {margin-top: 60px;}
    }
}

/*--------------------------------- 대출서류 제출 --------------------------------*/
.cptSubmitDoc {
    @extend .cptLoanDetail;
    .cardItem {
        .card { cursor: pointer;}
        input[type=checkbox]:checked + .card {
            border: 2px solid #bfdfff; padding: 36px 39px 114px; background: #f2f9ff;
            .infoGroup {bottom: 39px; left: 39px;}
        }
    }
    .comBtnGroup {
        &:last-child {margin-top: 60px;}
    }
    .fileArea {
        margin-top: 40px; padding-top: 40px; border-top: 1px solid #dcdcdc;
        .fileTit {
            display: flex; align-items: center;
            .tit {
                b {font-size: 18px; font-weight: 500; color: #444; display: block;}
                i {margin-top: 5px; font-style: normal; font-size: 14px; font-weight: 500; color: #444; display: block;}
            }
            .btnArea {
                margin-left: auto; position: relative;
                input[type=file] {width: 1px; height: 1px; font-size: 0; overflow:hidden;border:0;clip:rect(1px,1px,1px,1px);clip-path:inset(50%);position: absolute; left: -1px; top: -1px;}
                input[type=file] + label {min-width: 100px; padding: 0 20px; height: 40px; border-radius: 7px; text-align: center; line-height: 39px; font-size: 14px; color: #fff; background: #444; cursor: pointer; display: inline-block;}
            }
        }
        .fileList {
            margin-top: 20px; display: flex;  column-gap: 20px;
            .imgWrap {
                width: calc(20% - 16px); height: 190px; border: 1px solid #dcdcdc; border-radius: 25px; position: relative; text-align: center; overflow: hidden;
                img {
                    border: 10px solid #fff; overflow: hidden; width: 100%; height: 100%; object-fit: cover; border-radius: 25px;
                    &.noFile {border: none;}
                }
                .btnRemove {width: 27px; height: 27px; font-size: 0; background: url(../../images/common/ico_reset.png) center no-repeat; position: absolute; top:12px; right: 12px;}
            }
        }
    }
}

/*--------------------------------- 대출신청 순서 --------------------------------*/
.cptLoanStep {
    width: 100%;
    //20240404 대출신청 좌측메뉴(step) 고정 관련 css
    &.on {
        position:sticky; top:0;
        .stepArea {padding-top: 25px;}    
    }
    .stepArea {padding-top: 80px;}
    .stepTit {margin-bottom: 40px; font-size: 28px; font-weight: 500;}
    .stepGroup {
        .stepItem {
            width: 100%; margin-bottom: 50px; position: relative;
            &::after {display: block; content: ""; width: 2px; height: 20px; background: #ccc; position: absolute; left: 12px; bottom: -35px;}
            &:last-child::after {display: none;}
            & > span { display: inline-block; vertical-align: middle;}
            .num {width: 25px; height: 25px; margin-right: 10px; border-radius: 50%; background: #ccc; text-align: center; line-height: 24px; font-size: 13px; font-weight: 500; color: #fff;}
            .stepName {font-size: 16px; font-weight: 500; color: #ccc;}

            &.done {
                &::after {background: #666;}
                .num {background: #666;}
                .stepName {color: #666;}
            }

            &.now {
                &::after {background: #005ce6;}
                .num {background: #005ce6;}
                .stepName {color: #005ce6;}
            }
        }
    }
}

/*--------------------------------- 전자계약 리스트 --------------------------------*/
.cptContractList {
    .cptForm + & {margin-top: 120px;}
    .listAlert {
        width: 100%; margin: 80px 0 40px; text-align: center;
        .alertTit {
            width: 100%; font-size: 24px; color: #444; font-weight: 700; display: inline-block;
            b {color: #005ce6;}
        }
        i {margin-top: 5px; font-style: normal; font-size: 18px; color: #444;} 
    }

    .listGroup {
        .item {
            margin-bottom: 20px; border-radius: 25px; border: 1px solid #dcdcdc; padding: 30px 40px; display: flex; align-items: center; justify-content: space-between;
            &:last-child {margin-bottom: 0;}
            .itemTit {
                .num {font-size: 20px; font-weight: 700; color: #000;}
                .tit {margin-top: 5px; font-size: 16px; font-weight: 500; color: #444;}
            }
            .btnArea {
                text-align: right;
                .btn {min-width: 175px; height: 40px; padding: 0 30px; border-radius: 7px; font-size: 14px; font-weight: 500; text-align: center; line-height: 39px;}
            }
        }
    }
}

/*--------------------------------- 저당설정 확인사항 --------------------------------*/ 
.cptConfirmRadio {
    margin-top: 60px; padding-top: 60px; border-top: 1px solid #dcdcdc;
    .cfrmArea {
        margin-bottom: 60px;
        .cfrmTit {font-size: 18px; font-weight: 500; color: #000;}
        .cfrmDesc {padding: 0 20px; margin: 20px; font-size: 16px; color: #444; line-height: 1.75; word-break: keep-all;}
        .detailBox {
            width: 100%; border-radius: 15px; border: 1px solid #dcdcdc; padding: 27px 30px;
            .item {
                margin-bottom: 20px;
                &:last-child {margin-bottom: 0;}
                .tit {display: block; font-size: 16px; color: #444; font-weight: 700;}
                .exp {display: block; margin-top: 10px; font-size: 14px; color: #444; font-style: normal; word-break: keep-all;}
            }
        }
        .radioWrap {
            margin-top: 40px; text-align: center; position: relative;
            input[type=radio] + label {min-width: 365px; height: 65px; padding: 0 20px; border: 1px solid #ccc; border-radius: 10px; line-height: 64px; font-size: 16px; font-weight: 500; color: #444; display: inline-block; cursor: pointer;}
            input[type=radio]:checked + label {border: 2px solid #005ce6; padding: 0 19px; line-height: 63px; color: #005ce6; font-weight: 500;}
        }
    }
}

/*--------------------------------- 서명 입력 캔버스 --------------------------------*/ 
.cptSignCanvas {
    .canvasArea {width: 100%; height: 250px; border: 1px solid #dcdcdc; background: #f7f8fc;}
    .btnArea {
        margin-top: 10px; text-align: right;
        .cnvsRemove {padding-left: 20px; font-size: 14px; color: #000; background: url(../../images/common/ico_reload.png) left center no-repeat;}
    }
}

/*--------------------------------- 예금주 확인 로딩바 - 일치 불일치 --------------------------------*/ 
.cptCheckProcess {
    width: 100%;
    .loadingWrap {
        padding: 80px 0; text-align: center;
        .loadingBar {
            width: 400px; height: 7px; margin: 0 auto; border-radius: 3.5px;  background: #f3f3f3; box-shadow:  0 3px 7px 0 rgba(68, 68, 68, 0.04); overflow: hidden;
            .nowLoad {font-size: 0; height: 7px; border-radius: 3.5px; background: #005ce6; animation: loadbar 3s linear infinite;}

            @keyframes loadbar {
                from {width: 0;}
                to {width: 100%;}
            }
        }
        .loadingInfo {margin-top: 30px; font-size: 16px; font-weight: 500; color: #444; line-height: 1.625;}
    }
    .infoArea {
        border-top: 1px solid #dcdcdc; padding: 80px 0; text-align: center;
        &:first-child {margin-top: 80px;}
        .infoTit {
            font-size: 20px; font-weight: 500;
            &.t-red {color: #fe5f5f;}
            &.t-blue {color: #005ce6;}
        }
        .desc {margin-top: 20px; font-size: 16px; color: #444; line-height: 1.625;}
        .adds {margin-top: 40px; font-size: 14px; color: #666; line-height: 1.4;}
        .comBtnGroup {margin-top: 30px;}
    }
}

/*--------------------------------- 전자계약서 작성 완료 --------------------------------*/  
.cptInfoComplete {
    width: 100%;
    .infoBox {
        & + .infoBox {margin-top: 60px;}
        width: 100%; padding: 80px 20px; border: 1px solid #dcdcdc; border-radius: 25px; text-align: center;
        .infoTit {
            padding-top: 95px; font-size: 20px; color: #000; font-weight: 500; line-height: 1.6; background: url(../../images/common/ico_approve.png) center top no-repeat;
            &.noData {padding-top: 200px; background-image: url(../../images/sub/icon_nodata.png);}
        }
        .infoWrap {
            max-width: 400px; margin: 40px auto;
            .item {
                padding: 18px 0; border-bottom: 1px solid #dcdcdc;
                &:last-child {border-bottom: none;}
                .data {
                    display: flex; align-items: center;
                    dt {width: 150px; margin-right: 10px; font-size: 14px; font-weight: 700; color: #444; text-align: left;}
                    dd {width: calc(100% - 160px); font-size: 18px; font-weight: 500; color: #000;text-align: left;}
                }
            }
        }
        .adds {margin-top: 20px; font-size: 16px; color: #444;}
        .comBtnGroup {margin-top: 30px;}
    }
}

/*--------------------------------- 기업개요 ---------------------------------*/
.titleBox {
    width:100%; position:relative;

    .title {font-size:24px; color:#000; font-weight:bold; line-height:32px; letter-spacing:-0.6px;}
    .desc {font-size:16px; color:#000; font-weight:400; line-height:32px; letter-spacing: -0.4px; margin: 20px 0;}
}

.aboutCon {
    width:1040px;
}

.aboutInfo {
    width:100%; margin-top:60px;

    .title {font-size:24px; color:#000; font-weight:bold; line-height:1.33; letter-spacing:-0.6px; margin-bottom:20px;}
    .desc {font-size:16px; color:#000; font-weight:400; line-height:2; letter-spacing:-0.4px; margin-bottom:20px;}
}


/*--------------------------------- 이용제공현황 20240403 ---------------------------------*/
.cptContFinance {
    .financeArea {
        .titbtnBox {
            display:flex; flex-flow: row nowrap; align-items: center; justify-content: space-between; margin-bottom:20px;

            p {
                font-size:24px; color:#000; font-weight:bold; line-height:42px; letter-spacing: -0.6px;
            }

            button {
                background:#444; border-radius:7px; padding:10px 20px; text-align:center; font-size:14px; color:#fff; font-weight:400; line-height:32px; letter-spacing:-0.35px;
            }
        }

        .comBtnGroup {margin-top:60px;}
    }
}

/*경영이념 추가(신규)*/

.philoTitArea {
    width:100%; margin-bottom:40px;
    
    > p.philoTit {font-size:24px; color:#000; font-weight:500; line-height:24px; letter-spacing: -0.6px; margin-bottom:10px;}
    > p.philoDesc {font-size:16px; color:#000; font-weight:400; line-height:28px; letter-spacing: -0.4px;}
}

.philoBox {
    width:100%; position:relative;

    ul {
        display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-start; column-gap:22px; margin-bottom:60px;

        li {
            width:332px; position:relative; padding:60px 40px; height:500px;

            &:first-of-type {background:url(../../images/sub/philo_img01.png)0 0 no-repeat;}
            &:nth-of-type(2) {background:url(../../images/sub/philo_img02.png)0 0 no-repeat;}
            &:last-of-type {background:url(../../images/sub/philo_img03.png)0 0 no-repeat;}

            &::after {content: ''; position:absolute; bottom:-50px; left:50%; transform:translateX(-50%); width:40px; height:40px; background:url(../../images/sub/ico_philo02.png)50% 50% no-repeat;}

            .title {font-size:24px;color:#fff; font-weight:500; line-height:24px; letter-spacing:-0.6px;}

            .subTit {font-size:16px; color:#fff; font-weight:400 ; line-height:24px; letter-spacing: -0.4px;}

            .desc {position:absolute; bottom:60px; left:40px; font-size:16px; color:#fff;  font-weight:400 ; line-height:24px; letter-spacing: -0.4px;}
        }
    }
}

/*대출신청 플로팅 이미지 추가 2024.07.09*/
.floatingCover {
    position:fixed; bottom:70px; right:100px; width:120px; height:174px;
    &.floatNone {position:absolute; bottom:-150px; right:100px;}
    > a {
        display:block; position:relative; width:100%; height:100%;
        &::before {content:''; width:100px; height:19px; background: url(../../images/sub/floating_shadow.png) center bottom no-repeat; position:absolute; bottom:-11px; left:50%; transform:translateX(-50%);}
        &::after {content:''; width:120px; height:174px; background:url(../../images/sub/floating_btn.png) center bottom no-repeat; position:absolute; z-index:10; transform-origin: bottom; transform:rotate(-8deg); animation: actHeart 1.5s infinite; bottom:-15px;}
    }
}
@keyframes actHeart {
    0% {
        transform:rotate(-8deg);
    }
  
    50% {
        transform:rotate(8deg);
    }

    100% {
        transform:rotate(-8deg);
    }
}