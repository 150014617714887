/* --------------------------- 서브 상단 비주얼 --------------------------- */
.cptSubVisual{
    width:100%; min-width:1440px; padding:0 20px; height:140px;
    background:#F2F9FF url(../../images/common/visual01_bg.png) center top no-repeat;

    .visualArea{
        width:1400px; padding:44px 0 ; margin:0 auto;
        display:flex; flex-flow:row nowrap; align-items:end; justify-content: space-between;
        .textGroup {
            width:50%; 
            .title{
                width:100%; font-size:24px; font-weight:500; color:#000; line-height:1.5;
            }
            .subDesc{
                width:100%; margin-top:5px; font-size:14px; font-style:normal; font-weight:400; line-height:1.5; color:#333; display: inline-block;
            }
        }
        .locaGroup{
            width:50%;
            display:flex; flex-flow: row nowrap; align-items: center; justify-content: flex-end;
            .item{
                padding-left:40px; display:inline-block; position:relative;
                
                a{
                    font-size:14px; line-height:1.5; color:#666;

                }
                &::after{
                    content:""; width:12px; height:12px; background: url(../../images/common/bread_crumb_ank.png)50% 50% no-repeat; position:absolute; left:16px; top:3px;
                }
                &:first-child{
                    padding-left:0;
                    
                    a{
                        width:18px; height:18px; font-size:0; vertical-align:top; background:url(../../images/common/loca_home.png) center no-repeat; display:inline-block;
                    }
                    &::after{
                        display:none;
                    }
                }
                &:last-child{
                    a{
                        color:#000; font-weight:500;
                    }
                }
            }
        }
    }
}