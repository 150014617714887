
/* ------------------------------- 상단 메뉴 영역 ------------------------------- */
.cptGnb{
    width:100%; position:relative; z-index:100;

    .gnbArea{
        width:100%;
        
        /* 최상단 영역 */
        .gnbTop{
            width:100%; min-width:1440px; height:70px; background:#4b9ff9;

            .topArea{
                width:100%; max-width: 1440px; padding:19px 20px; margin:0 auto; 

                i {font-size:30px; font-style:normal; line-height:1; color:#fff;}
            }
        }

        /* 상단 메뉴 영역 */
        .gnbBody{
            .bodyArea{
                width:1440px; height:100px; padding:0 20px; margin:0 auto; display:flex; flex-wrap:nowrap; align-items:center;

                .logo{
                    width:150px; padding-right:20px; height:27px;
                }

                .menuArea{
                    width:100%; height:100%;
                    
                    .depth1Group{
                        width:100%; height:100%; display:flex; flex-wrap:nowrap; justify-content:center; align-items: center; column-gap:132px;
                        // column-gap:150px;

                        .depth1Menu{
                            .depth1Name{
                                a{
                                    display:flex; align-items:center; font-weight:500; font-size:20px;
                                }
                            }
                            // .depth2Group{
                            //     display:none;
                            // }
                        }
                    }

                    .dropDownMenu {
                        width:100%; min-width:1440px; background:#fff; padding:40px 0 70px 0; position:absolute; top:170px; left:0; z-index:99; display:none; flex-flow:row nowrap; align-items: flex-start; justify-content: center; border-top:1px solid #dcdcdc;  border-radius:0 0 35px 35px; box-shadow:0px 6px 10px -10px #333;
        
                        > ul {
                            display:flex; flex-flow:row nowrap; align-items: flex-start; justify-content: center;
        
                            > li {
                                width:max-content; margin-left:65px;
                                &:first-of-type {margin-left:65px;}
                                &:nth-of-type(3) {margin-left:83px;}
                                &:last-of-type {margin-left:70px;}
                                // width:max-content; margin-left:75px;
                                // &:first-of-type {margin-left:71px;}
                                // &:nth-of-type(3) {margin-left:95px;}
                                // &:last-of-type {margin-left:80px;}
                                p {font-size:14px; color:#666; font-weight:500; line-height:1; letter-spacing:-0.35px; margin-bottom:30px;}
        
                                li {
                                    margin-bottom:20px;
        
                                    a {
                                        font-size:16px; color:#444; font-weight:500; line-height:1; letter-spacing:-0.4px; transition:all 0.4s;
                                        &:hover {
                                            color:#005ce6;
                                        }
                                    }
                                }
                            }
        
                        }
                    }
                }

                .quickArea{
                    height:40px;

                    .quickGroup{
                        width:100%; height:40px; border:1px solid #dcdcdc; border-radius:20px; background:#fff; overflow:hidden; position:relative;

                        &::after {
                            content:""; width:10px; height:10px; background:url(../../images/main/open.svg) center / 10px no-repeat; display:block; transform:rotate(180deg); position:absolute; top:15px; right:20px;
                        }
                        &:hover {
                            height:auto; overflow:auto;

                            &::after {
                                transform:rotate(0deg);
                            }
                            
                            .quickLink{
                                &:hover{
                                    background:rgba(0,0,0,0.05);
                                }
                            }
                        }

                        .quickMenu {
                            .quickLink {
                                padding:0 40px 0 20px; font-size:14px; color:#111; line-height:38px; white-space:nowrap; display:block; font-weight:500;
                            }
                        }
                    }
                }

                .allMenuBtn{
                    width:23px; height:40px; margin-left:20px; border:0; position:relative; outline:none;
                    
                    span{
                        height:3px; font-size:0; background:#444; display:block; position:absolute; right:0px; transition-duration:0.4s;

                        &:nth-child(1){
                            width:23px; top:10px;
                        }
                        &:nth-child(2){
                            width:15px; top:19px;
                        }
                        &:nth-child(3){
                            width:23px;top:28px;
                        }
                    }

                    &.active{
                        span{
                            &:nth-child(1){
                                width:23px; top:19px; right:-6px; transform:rotate(-45deg);
                            }
                            &:nth-child(2){
                                width:0; top:19px; opacity:0;
                            }
                            &:nth-child(3){
                                width:23px; top:19px; right:-6px; transform:rotate(45deg);
                            }
                        }
                    }
                }

                .allMenuCov {
                    display:none; position:fixed; top:0; left:0; width:100%; height:100%; background:#fff; z-index:999; padding:120px 0 190px 0; overflow-y:scroll;
                    
                    &::-webkit-scrollbar{
                        width:8px; background:rgba(0,0,0,0.1);
                    }
                    &::-webkit-scrollbar-thumb{
                        background:rgba(0,0,0,0.2);
                    }

                    > div {
                        width:1440px; margin:0 auto; display:flex; flex-flow: row nowrap; align-items: flex-start; justify-content: flex-start;

                        > ul {
                            width:100%; display:flex; flex-flow: column; align-items: flex-start; justify-content: flex-start;
                            > li:first-of-type {
                                padding-top:0;
                            }
                            > li:last-of-type {
                                padding-bottom:0;
                            }
                            > li {
                                width:100%; min-height:32px; display:flex; flex-flow: row nowrap; align-items:flex-start; justify-content: flex-start; padding:80px 0 80px 40px;

                                .menuTitle {
                                    width:163px; margin-right: 157px;

                                    p {font-size:32px; color:#000; font-weight:500; line-height:1; letter-spacing:-1.6px; width:max-content; }
                                }

                                .menuSub {
                                    width: calc(100% - 340px);

                                    > ul {
                                        width:100%; display:flex; flex-flow:row wrap; align-items: flex-start; justify-content: flex-start;  gap:40px 0;

                                        > li {
                                            width:calc(100% / 4); line-height:32px;
                                            
                                            > h3 {
                                                a {font-size:20px;color:#444; font-weight:bold; line-height:32px; letter-spacing:-0.5px;}   
                                            }
                                            > ul {
                                                margin-top:30px;
                                                &.have3rd {
                                                    > li {
                                                        margin-bottom:20px;
    
                                                        &:last-of-type {margin-bottom:0;}
                                                    }
                                                }

                                                li {
                                                    a{
                                                        font-size:16px; color:#444; font-weight:400; line-height:1; letter-spacing:-0.7px;
                                                    }
                                                }
                                            }
                                        }

                                    }
                                }
                            }
                        }

                        .allMenuBtn{
                            width:23px; height:40px; margin-left:20px; border:0; position:relative; outline:none;
                            
                            span{
                                height:3px; font-size:0; background:#444; display:block; position:absolute; right:0px; transition-duration:0.4s;
        
                                &:nth-child(1){
                                    width:23px; top:10px;
                                }
                                &:nth-child(2){
                                    width:15px; top:19px;
                                }
                                &:nth-child(3){
                                    width:23px;top:28px;
                                }
                            }
        
                            &.active{
                                z-index:9999; margin:0; height:21px;
                                span{
                                    &:nth-child(1){
                                        width:23px; top:10px; right:0; left:-12px; transform:rotate(-45deg);
                                    }
                                    &:nth-child(2){
                                        width:0; top:10px; opacity:0;
                                    }
                                    &:nth-child(3){
                                        width:23px; top:10px; right:0; left:-12px; transform:rotate(45deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
