
:root {
  --key-blue01:#005ce6;
}

/* 본고딕 Thin */
@font-face {
    font-family: "NotoSansCJKkr";
    font-style: normal;
    font-weight: 200;
    src: url("../../common/webfont/NotoSansKR-Thin.woff") format("font-woff"),
      url("../../common/webfont/NotoSansKR-Thin.woff2") format("font-woff2"),
      url("../../common/webfont/NotoSansKR-Thin.otf") format("opentype");
      
  }
  
  /* 본고딕 Light */
  @font-face {
    font-family: "NotoSansCJKkr";
    font-style: normal;
    font-weight: 300;
    src: url("../../common/webfont/NotoSansKR-Light.woff") format("font-woff"),
      url("../../common/webfont/NotoSansKR-Light.woff2") format("font-woff2"),
      url("../../common/webfont/NotoSansKR-Light.otf") format("opentype");
      
  }
  /* 본고딕 Regular */
  @font-face {
    font-family: "NotoSansCJKkr";
    font-style: normal;
    font-weight: 400;
    src: url("../../common/webfont/NotoSansKR-Regular.woff") format("font-woff"),
      url("../../common/webfont/NotoSansKR-Regular.woff2") format("font-woff2"),
      url("../../common/webfont/NotoSansKR-Regular.otf") format("opentype");
  }
  /* 본고딕 Medium */
  @font-face {
    font-family: "NotoSansCJKkr";
    font-style: normal;
    font-weight: 500;
    src: url("../../common/webfont/NotoSansKR-Medium.woff") format("font-woff"),
      url("../../common/webfont/NotoSansKR-Medium.woff2") format("font-woff2"),
      url("../../common/webfont/NotoSansKR-Medium.otf") format("opentype");
  }
  /* 본고딕 Bold */
  @font-face {
    font-family: "NotoSansCJKkr";
    font-style: normal;
    font-weight: 700;
    src: url("../../common/webfont/NotoSansKR-Bold.woff") format("font-woff"),
      url("../../common/webfont/NotoSansKR-Bold.woff2") format("font-woff2"),
      url("../../common/webfont/NotoSansKR-Bold.otf") format("opentype");
  }
  /* 양평 normal */
  @font-face {
    font-family: "YangPyeongM";
    font-style: normal;
    font-weight: 500;
    src: url("../../common/webfont/YangPyeongM.woff") format("woff");
  }
  /* 양평 Bold */
  @font-face {
    font-family: "YangPyeongB";
    font-style: normal;
    font-weight: 700;
    src: url("../../common/webfont/YangPyeongB.woff") format("woff");
  }
  /* 양평 Light */
  @font-face {
    font-family: "YangPyeongL";
    font-style: normal;
    font-weight: 300;
    src: url("../../common/webfont/YangPyeongL.woff") format("woff");
  }
  @font-face {
    font-family: 'RedHatDisplay';
    font-style: normal;
    font-weight: 300 900;
    font-display: swap;
    src: url("../../common/webfont/RedHatDisplay.woff2") format('woff2');
  }
* {box-sizing: border-box; font-family:'Roboto', 'NotoSansCJKkr', sans-serif; letter-spacing: -0.05em;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button {margin:0;padding:0}
fieldset,img {border:0 none}
dl,ul,ol,menu,li {list-style:none}
blockquote, q {quotes: none}
blockquote:before, blockquote:after,q:before, q:after {content:'';content:none}
input,select,textarea,button {vertical-align:middle}
input, textarea {-webkit-border-radius:0; -webkit-appearance:none;}
button {border:0 none;background-color:transparent;cursor:pointer}
body {background:#fff}
body,th,td,input,select,textarea,button {font-size:12px;line-height:1.5; font-family:'Montserrat', 'NotoSansCJKkr', sans-serif; color:#000} /* color값은 디자인가이드에 맞게사용 */
a {color:#000;text-decoration:none}
a:active, a:hover {text-decoration:none;}
address,caption,cite,code,dfn,em,var {font-style:normal;font-weight:normal}
caption {font-size: 0;}

input[type=radio] {width:1px; height:1px; font-size:0; opacity:0.01; overflow:hidden;}
input[type=checkbox] {width:1px; height:1px; font-size:0; opacity:0.01; overflow:hidden;}

select {-moz-appearance:none; -webkit-appearance:none; appearance:none; -moz-border-radius:0; -webkit-border-radius: 0;border-radius: 0;  background:#fff url(../../common/images/icon_select.png) calc(100% - 9px) center no-repeat;}
select::-ms-expand {display:none}
label {font-size: 0;}

.skip .quickBtn {display: block; width: 100%; height: 0px;background: #092473; font-size: 15px; color: #fff; overflow: hidden; text-align: center;}
.skip .quickBtn:focus {height:auto;}

.hide {font-size:0 !important; position:fixed; left:-5000px; top:0;}

.input[type=text] {}


:root::-webkit-scrollbar{
  width:8px; background:rgba(0,0,0,0.1);
}
:root::-webkit-scrollbar-thumb{
  background:rgba(0,0,0,0.2);
}