/* -------------------------------- 테이블 - basic -------------------------------- */
.cptTable {
    width:100%; position:relative;

    .rightText{
        width:100%; font-size:14px; font-weight:500; line-height:20px; color:#000; text-align:right; position:absolute; left:0; top:-30px;
    }

    table {
        width:100%; border-top:1px solid #000;
        
        th {
            font-size:16px; color:#000; font-weight:500; line-height: 24px; text-align:center; border-bottom:1px solid #dcdcdc; padding:28px 0;
        }

        td {
            span {
                display:block; font-size:16px; color:#000; font-weight:500; line-height: 24px; text-align:center; border-bottom:1px solid #dcdcdc; padding:33px 0;
                
                &.txtGry {
                    font-family:"RedHatDisplay"; color:#888;
                }
                
                &.noData {
                    color:#444;
                }
            }
            &.lBdr{border-left:1px solid #dcdcdc;}
            &.form {
                padding:11px 20px;
            }
            .indent {
                width:100%; font-size:16px; font-weight:400; line-height:1.5; color:#000; text-indent:-18px; padding-left:18px;
            }
            .btnTable {
                height:40px; padding:0 20px; font-size:14px; font-weight:500; line-height:38px; color:var(--key-blue01); border:1px solid var(--key-blue01); border-radius:7px; background:#fff;
                &.download {
                    padding-right:52px; background:#fff url(../../images/common/icon_btn_download.svg) calc(100% - 20px) center no-repeat;
                }
            }
        }
    }
}